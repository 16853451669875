import {Tooltip} from 'antd';
import './NewProductCard.css';
import {useIntl} from 'react-intl';
import {Context} from '../../../Store';
import {NewProductModal} from './NewProductModal';
import {TagsFilled} from '@ant-design/icons';
import * as HttpStatus from 'http-status-codes';
import {Utils} from '../../../../services/Utils.class';
import {AMPMPurchaseProduct} from '../../actions/AMPMPurchaseProduct';
import React, {useContext, useState, useEffect} from 'react';
import {ProductInterface} from '../../../product/ProductInterface';
import {HttpRequest} from '../../../../services/HttpRequest.class';

export function NewProductCard(props:{
  item:any,
  isClicking?:boolean,
  product:ProductInterface
}) {
  const intl = useIntl();
  const merchant = Utils.getMerchantByUrl();
  const [state, dispatch] = useContext(Context);
  const [show, setShow] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hideProductCode, setHideProductCode] = useState(false);
  const [hideAvailableStock, setHideAvailableStock] = useState(false);
  const [hideProductDescription, setHideProductDescription] = useState(false);
  const [promotion, setPromotion]=useState({})
  useEffect(()=>{
    if(props.product.promotions) {
      let temp = props.product.promotions.sort((a, b) => b.percent - a.percent)[ 0 ];
      setPromotion(temp);
    }
  },[])
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('product');

  useEffect(() => {
    getRules();
    if ( id === props.product.id){
      setShow(true)
    }
  }, [])

  const putProductPrice = async (product:any)  => {
    setLoading(true);
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const productRequest = await request.put(`product/${product.id}`, product);
    if (productRequest.status !== HttpStatus.CREATED) {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id:'CASHIER_STOCK_PRODUCT_CARD__ERROR_NOTIFICATION_TITLE_UPDATING_PRODUCT'}),
        intl.formatMessage({id:'CASHIER_STOCK_PRODUCT_CARD__ERROR_NOTIFICATION_MESSAGE_UPDATING_PRODUCT'})
      );
    }

    if (productRequest.status === HttpStatus.CREATED) {
      const position = getProductPositionState(product.id);
      if (Object.keys(position).length) {
        dispatch({
          type: 'UPDATE-PRODUCT',
          payload: {
            ...position,
            product: product
          }
        });
      }
    }

    setLoading(false);
  };

  const getRules = () => {
    if (state.ruleParams.hideProductCode) setHideProductCode(true);
    if (state.ruleParams.hideAvailableStock) setHideAvailableStock(true);
    if (state.ruleParams.hideProductDescription) setHideProductDescription(true);
  }

  const getProductById = (id:string) => {
    for (const product of state.products[state.merchantId]) {
      for (const currentProduct of product.models) {
        if (currentProduct.id == id) {
          return currentProduct;
        }
      }
    }

    return {
      details: []
    };
  };

  let img = (
    <i
      id={`Cashier-item-img-${props.product.name}`}
      className={'large material-icons Customer-merchant-icon'}
    >loyalty</i>
  );

  const getUnitaryIVA = (product:any) => {
    return Math.floor(product?.sku?.taxAsInt / 100);
  };

  const getProductPositionState = (id: string) => {
    let productPosition = {};
    state.products[state.merchantId]?.map((product: any, index: number) => {
      product.models?.map((currentProduct: any, key: number) => {
        if (currentProduct.id == id) {
          productPosition = {
            key: key,
            index: index
          };
        }
      });
    });

    return productPosition;
  };

  const getDiscount = (price:number, discount:any) => {
    let discountToDiv = discount * 100;
    return (100 - Math.floor(discountToDiv / price));
  }

  const getTotalPriceProduct = (product:any) => {
    let total = product.price * (cart[product.id] ? cart[product.id] : 1);
    if (promotion ) {
      const {type, value, percent} = promotion;
      if (type === 'regular' && !product.promoPrice && cart[product.id] >= 1) {
        let productPriceDiscount = ((product.price / 100) * percent);
        total = (product.price - productPriceDiscount) * cart[product.id]
      }
      if (type === 'quantity' && !product.promoPrice && cart[product.id] >= Number(value)) total -= (product.price / 100) * percent;
    }
    return total;
  };

  if (props.item.models.filter(e=>e.imgId !== '').length) {
    img = (
      <img
        alt={'logo'}
        id={`${props.product.imgId}`}
        className={'New-Product-item-Img'}
        src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${props.item.models.filter(e=>e.imgId !== '')[0].imgId}`}
      />
    );
  }
  let price = (
    <p id={`Cashier-item-price-${props.product.id}`} className={promotion?.percent || props.product.promoPrice ? 'New-product-item-price' : 'New-product-item-price-withOutPromo'}>
      {state.currentMerchant.isoCurrency.symbol +Utils.formatMoney(( getUnitaryIVA(props.product)+ props.product.price) * (cart[props.product.id] ? cart[props.product.id] : 1))}
    </p>
  );

  let promoPrice = (<></>)

  if(props.product.promo?.percent){
    price=    <p id={`Cashier-item-price-${props.product.id}`} className={'New-item-price-Promo'}>
      {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(((getUnitaryIVA(props.product)+ props.product.price * (1+(props.product.promo?.percent/100))) * (cart[props.product.id] ? cart[props.product.id] : 1)))}
    </p>
    promoPrice =   (
      props.product.promoPrice && props.product.promoPrice > 0 ? (
          <p id={`Cashier-item-price-${props.product.id}`} className={'New-product-item-price-withOutPromo'}>
            {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(( getUnitaryIVA(props.product)+ props.product.price) * (cart[props.product.id] ? cart[props.product.id] : 1))}
          </p>
        )
        : <p/>
    );
  }







  let mark = '';
  let measure = '';
  props.product.details?.map((currentDetail: any) => {
    if (currentDetail.type === 'medida') measure = currentDetail.value;

    if (currentDetail.type === 'marca') mark = currentDetail.value;
  });

  const showModal = () =>{
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  return (
    <div className={'New-product-main-container'}>
      <div>
        {show ? <NewProductModal item={props.item} key={props.product.id} visible={show} setShowModal={setShow} product={props.product} products={state.products[merchant]}/> : ''}
      </div>
      <div className={'New-product-item-img'} onClick={()=> {
         showModal();
      }}>
        {img}
      </div>
      <span style={{backgroundColor:`${state.currentMerchant.styles.color2}`}} className={cart[props.product.id] ? 'Cashier-item-img-quantity New-item-img__full' : 'Cashier-item-img-quantity'}>
                {cart[props.product.id] ? cart[props.product.id] : 0}
            </span>

      <span id={`Cashier-item-name-${props.product.id}`} className={'New-product-item-name'}>{props.product.name}</span>

      {
        props.item.models[0].details.filter((e:any)=>e.type==='singleSelection').length||props.item.models[0].details.filter((e:any)=>e.type==='buyTogether').length?
          <span id={`Cashier-item-combo-${props.product.id}`} className={'Cashier-item-combo'}>Combo</span> :
          <></>
      }

      {promotion?.percent  || props.product.promoPrice && props.product.promoPrice > 0 ?
        <div className={'ampm-Icon-Outlined-Gift-card'}>
          <Tooltip title={props.product.promo.description}>
            <TagsFilled style={{fontSize: '20px'}}/>
          </Tooltip>
          {state.ruleParams?.promotionIconText &&
            <span className={'New-Icon-Promotion-Text'}>{Math.floor(props.product.promo?.percent)}%</span>
          }
        </div> : <></>
      }
      <div className={'New-item-controls-content'}>
        <div className={'New-item-price-Container'}>
          {price}
          {promoPrice}
        </div>
        <span className={'Cashier-item-controls'}>
                    {
                      props.product.details.filter((e:any)=>e.type==='singleSelection').length && !cart[props.product.id]?
                        <div className={(!props.product.quantity || props.product.price == 0)?'PurchaseProduct__unavailable':`New-product-Purchase`}
                             onClick={showModal}>
                          <span className={'Cashier-item-withOutStock'}>{intl.formatMessage({id:'CASHIER_ACTIONS__BUTTON_BUY'})}</span>
                        </div>
                        :
                        <AMPMPurchaseProduct  product={props.product} hideAvailableStock={hideAvailableStock}/>
                    }

                </span>
      </div>
    </div>
  )
}
