import {notification} from 'antd';
import * as moment from 'moment-timezone';
import {useRouteMatch} from 'react-router';
import {IconType} from 'antd/lib/notification';
import {HttpRequest} from './HttpRequest.class';
import {isEqual, isObject, transform} from 'lodash';

import {UIEvent} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {TDocumentDefinitions} from 'pdfmake/interfaces';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class Utils {

  /**
   * Get time moment time zone
   * @param tz
   * @param format
   */
  static moment(tz:string, format:string = ''): string {
    const tzOffset = moment.tz(Date.now(), tz);
    return tzOffset.format(format);
  }

  /**
   * Get time moment time zone
   * @param timestamp
   * @param tz
   * @param format
   */
  static momentByTimestamp(timestamp:number, tz:string, format:string = ''):string {
    moment.tz(Date.now(), tz);

    const tzOffset = moment.unix(timestamp);
    return tzOffset.format(format);
  }

  /**
   * Get the time zone from a Country ISO code
   * @param countryCode
   */
  static getTZCountry(countryCode:string): string {
    return moment.tz.zonesForCountry(countryCode).toString();
  }

  /**
   * Get current timestamp
   */
  static nowTimeStamp (): number {
    return Math.floor(+new Date() / 1000)
  }

  /**
   * Get current Date
   */
  static getDate(): Date {
    return new Date();
  }

  /**
   * Format timestamp YYYY-MM-DD hh:mm:ss
   * @param timestamp
   */
  static dateFromTS(timestamp:number) {
    const ts_ms = timestamp * 1000;
    const date_ob = new Date(ts_ms);

    const year = date_ob.getFullYear();
    const month = ('0' + (date_ob.getMonth() + 1)).slice(-2);
    const day = ('0' + date_ob.getDate()).slice(-2);
    const hours = ('0' + date_ob.getHours()).slice(-2);
    const minutes = ('0' + date_ob.getMinutes()).slice(-2);
    const seconds = ('0' + date_ob.getSeconds()).slice(-2);

    return  year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  /**
   * Format timestamp MM-DD-YYYY hh:mm:ss
   */
  static dateFormat() {
    const ts_ms = Utils.nowTimeStamp() * 1000;
    const date_ob = new Date(ts_ms);

    const year = date_ob.getFullYear();
    const day = ('0' + date_ob.getDate()).slice(-2);
    const month = ('0' + (date_ob.getMonth() + 1)).slice(-2);

    return  day + '-' + month + '-' + year;
  }

  /**
   * Check if value is a valid JSON structure
   * @param str
   * @returns {boolean}
   */
  static isJson (str:string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }

  /**
   * Check if variable is object type
   * @param object
   * @returns {boolean}
   */
  static isObject (object:any) {
    return isObject(object);
  }

  /**
   * Get object from bean
   * @param bean
   * @returns object
   */
  static objected (bean:any) {
    if (bean) {
      if (this.isJson(bean)) {
        return JSON.parse(bean);
      }

      if (this.isObject(bean)) {
        return bean;
      }
    }

    return {};
  }

  /**
   * Deep diff between two object, using lodash
   * @param  {Object} object
   * @param  {Object} base
   * @return {Object}
   */
  static objectDiff (object:any, base:any) {
    const baseSort:any = this.sortObjectByValues(base);
    const objectSort:any = this.sortObjectByValues(object);

    return transform(objectSort, (result:any, value:any, key:any) => {
      if (!isEqual(value, baseSort[key])) {
        result[key] = this.isObject(value) && this.isObject(base[key]) ? this.objectDiff(value, base[key]) : value;
      }
    });
  }

  /**
   * Sort object by values
   * @param object
   */
  static sortObjectByValues (object:any) {
    return object.sort((a:any, b:any) => {
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    });
  }

  /**
   * Generate unique reference id
   */
  static transactionReference () {
    const firstPart:number = (Math.random() * 46656) | 0;
    const secondPart:number = (Math.random() * 46656) | 0;

    const firstPartClear:string = ("000" + firstPart.toString(36)).slice(-3);
    const secondPartClear:string = ("000" + secondPart.toString(36)).slice(-3);

    return firstPartClear.toUpperCase() + secondPartClear.toUpperCase()
  }

  /**
   * Replacement string values by map
   * @param content
   * @param replacements
   */
  static replacementValues (content:string, replacements:any):string {
    for (const current in replacements) {
      if (replacements.hasOwnProperty(current)) {
        content = content.replace(`{{${current}}}`,  replacements[current]);
      }
    }

    return content;
  }

  /**
   * File to base64 encoded
   * @param file
   */
  static async toBase64 (file: Blob):Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * Mask sensitive data
   * @param data
   * @param name
   */
  static logSensitiveData (data:{body:object}, name:string) {
    switch (name) {
      case name = 'CreditCard':
      case name = `${process.env.STACK_NAME}CreditCardPost`:
      case name = `${process.env.STACK_NAME}CreditCardTokenized`:
        console.log('Data', JSON.stringify(Object.assign({}, data, {body: {}})));

        let modObject:any = Utils.objected(data.body);

        const originalCardNumber = modObject.cardNumber;
        const bin = modObject.cardNumber.toString().substring(0, 6);
        const lastDigits = modObject.cardNumber.toString().slice(-4);

        modObject.cardNumber = `${bin}XXXXXX${lastDigits}`;
        console.log('Body', JSON.stringify(modObject));
        modObject.cardNumber = originalCardNumber;
      break;

      default:
        console.log('Data', JSON.stringify(data));
    }
  }

  /**
   * Format number to money
   * @param amount
   * @param decimalCount
   * @param decimal
   * @param thousands
   */
  static formatMoney(amount:any, decimalCount = 2 | 0, decimal = ",", thousands = ".") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;


      if (decimalCount == 0) {
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j);
      } else {
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : "");
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Check if variable is a function type
   * @param functionToCheck
   */
  static isFunction(functionToCheck:any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  /**
   * Open link in new tab
   * @param url
   */
  static openNewTab (url:string) {
    if (url) {
      const check = window.open(url, '_blank');
      if (check) {
        check.focus()
      }
    }
  }

  /**
   * Check if browser is a mobile view
   */
  static isMobile () {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  /**
   * Check if string is url
   * @param url
   */
  static isUrl(url: string) {
    const match = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
    return url.match(match);
  }

  /**
   * Capitalize the first letter from a word
   * @param s
   */
  static capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  /**
   * Order an array
   * @param arr
   * @param orderType true - ASC, false - DESC
   */
  static orderArray = (arr:any[], orderType:boolean = true) => {
    return arr.sort((a, b) => (orderType) ? (a - b) : (b - a));
  }

  /**
   * Uniques values in array
   * @param arr
   */
  static uniqueArrayVal = (arr:any[]) => {
    return arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });
  }

  /**
   * Get the merchant by the url
   */
  static getMerchantByUrl = () => {
    return window.location.host.split('.')[0];
  }

  /**
   * Check if encrypt is stilling valid
   */
  static encryptIsValid = (timeEncrypt:any, timeToEnd:number) => {
    let isValid = false;
    const createdAtTimestamp = timeEncrypt;

    const currentTimestamp = Math.floor(Date.now() / 1000);
    const timeDifferenceInMinutes = Math.floor((currentTimestamp - createdAtTimestamp) / 60);

    if (timeDifferenceInMinutes <= timeToEnd) {
      isValid = true;
    }

    return isValid;
  }

  /**
   * Get last category in Cashier by the url
   */
  static getLastCategoryByUrl = () => {
    const arrayCategories = useRouteMatch().url.split('/');
    const lengthCategories = arrayCategories.length;
    return arrayCategories[lengthCategories - 1];
  }

  /**
   * Display a notification message
   * @param type
   * @param title
   * @param message
   */
  static showNotificationMessage = (type:IconType, title:string, message:string) => {
    notification[type]({
      message: `${title}`,
      description: `${message}`
    });
  };

  /**
   * Get all pages of a web page.
   *
   * @param headerInfo
   * @param pdfName
   * @param document
   */
  static savePages = (headerInfo:string, pdfName:string, document:any) => {
    const tableHeaderText = [...document.querySelectorAll('thead tr th')].map(thElement => ({ text: thElement.textContent, style: 'tableHeader' }));
    const tableRowCells = [...document.querySelectorAll('tbody tr td')].map((tdElement:any) => ({ text: tdElement.textContent, style: 'tableData' }));
    const tableDataAsRows = tableRowCells.reduce((rows:any[], cellData, index) => {
      if (index % 5 === 0) {
        rows.push([]);
      }

      rows[rows.length - 1].push(cellData);
      return rows;
    }, []);

    const docDefinition:TDocumentDefinitions = {
      header: { text: headerInfo, alignment: 'center'},
      footer: function(currentPage, pageCount) { return ({ text: `Page ${currentPage} of ${pageCount}`, alignment: 'center' }); },
      content: [
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              tableHeaderText,
              ...tableDataAsRows,
            ]
          },
          layout: {
            fillColor: function(rowIndex) {
              if (rowIndex === 0) {
                return '#0f4871';
              }
              return (rowIndex % 2 === 0) ? '#f2f2f2' : null;
            }
          },
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 20, 0, 80],
        },
        tableHeader: {
          margin: 12,
          color: 'white',
        },
        tableData: {
          margin: 12,
        },
      }
    };

    pdfMake.createPdf(docDefinition).download(pdfName);
  }

  /**
   * Send Email in UI
   *
   * @param message
   * @param subject
   * @param to
   * @param template
   */
  static sendEmail = async (message:string, subject:string, to:any[], template:string = 'simpleTXT') => {
    const sendEmailRequest = new HttpRequest(process.env.REACT_APP_API_HOST || '', true);
    return await sendEmailRequest.post(
        [
          {
            to: to,
            subject: subject,
            data: {TXT: message},
            templateName: template,
            from: process.env.REACT_APP_NO_REPLY_EMAIL,
          }
        ]
        , 'notification/email');
  }

  /**
   * Handle UI Event Scroll
   * @param event
   * @return Boolean
   */
  static handleUIEventScroll = (event:UIEvent<HTMLDivElement>) => {
    const {scrollTop, scrollHeight, clientHeight}:any = event.currentTarget;
    if (scrollTop + clientHeight === scrollHeight) {
      return true;
    } else if (scrollTop === 0) {
      return false;
    }
  }

  /**
   * Move to scroll
   * @param scrolling
   */
  static moveToScroll = (scrolling:Boolean) => {
    const pageScroll:any = document.querySelector(!scrolling ? '#page-bottom' : '#page-top');
    const scrollTo:any = document.querySelector(!scrolling ? '#scroll-to-bottom' : '#scroll-to-top');
    if (scrollTo) {
      scrollTo.addEventListener('click', () => pageScroll.scrollIntoView());
    }
  }
}
