export function CallbackPickUp (pickUpValues:any, pickUpErrors:any, updateOrder:any = (data:any) => {}, current:number) {
  if (!Object.keys(pickUpErrors).length && current) {
    let addressData = {};
    const stores = pickUpValues.pickup.stores || [];
    let clientProfileData = {email: pickUpValues.pickup.email?.trim()};
    const store = stores.find((store:any) => store.code === pickUpValues.pickup.code);
  
    clientProfileData = Object.assign(clientProfileData, {
      phoneNumber: store?.phoneNumber,
      lastName: pickUpValues.pickup.lastName,
      DNI: pickUpValues.pickup.identification,
      firstName: pickUpValues.pickup.firstName
    });
    
    addressData = Object.assign(addressData, {
      state: store?.state,
      postalCode: store?.postalCode,
      district: store?.info?.neighborhood,
    });
    
    if (store) {
      const pickupData = {
        delivered: {
          pickup: {
            code: store.code,
            name: store.name,
            address: addressData,
            clientProfile: clientProfileData
          }
        }
      };
      updateOrder(pickupData);
    }
  }
}
