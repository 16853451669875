import moment from 'moment';
import {Tooltip} from 'antd';
import {Context} from '../../Store';
import {TagsFilled} from '@ant-design/icons';
import {Utils} from '../../../services/Utils.class';
import {FormattedMessage, useIntl} from 'react-intl';
import {PurchaseProduct} from '../actions/PurchaseProduct';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import React, {useContext, useEffect, useState} from 'react';
import {ProductInterface} from '../../product/ProductInterface';
import {HeartOutlined, ShareAltOutlined} from '@ant-design/icons';
import {AddAndRemoveProduct} from '../actions/AddAndRemoveProduct';
import {Button, Comment, Form, Image, Input, List, Modal, Rate, Divider} from 'antd';

export function ProductModal (props:{
  item: any,
  products: any,
  visible:boolean,
  priceTotal:number,
  hideQuantity:boolean,
  setShowModal:Function,
  product:ProductInterface,
}){
  const intl = useIntl();
  const {TextArea} = Input;
  const names:string = 'Anonymous';
  const [state, dispatch] = useContext(Context);
  const [value, setValue]= useState<number>(0);
  const [details, setDetails]= useState<any>({});
  const [showCombo, setShowCombo]= useState<any>({});
  const [submitting, setSubmitting] = useState(false);
  const [valueComment, setValueComment] = useState('');
  const [comments, setComments] = useState<CommentItem[]>([]);
  const [isProductInFavorite, setIsProductInFavorite] = useState<any>();
  const [selectingCombo, setSelectingCombo]= useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductInterface>(props.product);
  const numbers:any = ['131', '412', '227', '144', '123', '403', '033', '032', '838', '882'];
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  interface CommentItem {
    author:string;
    datetime:string;
    content:React.ReactNode;
  }

  useEffect(() => {
    const productIndex:number = getProductsInFavorite();
    if (productIndex === -1) setIsProductInFavorite(false);
    else setIsProductInFavorite(true);
  }, []);

  useEffect(()=>{
    if(props.item.models.length>1) {
      setSelectingCombo(true);
    }
  },[])

  useEffect(()=>{
    if(props.product.details.length){
      setSelectedProduct(
        props.item.models.filter((e:any)=>
          (e.details.filter((f: any) => f.name === 'sideDish')[0]?.product.name === details['sideDish'] &&
           e.details.filter((f: any) => f.name === 'beverage')[0]?.product.name === details['beverage'])
        )[0]
      );

      setSelectingCombo(false);
    }
  }, [details])

  const setFavoriteProduct = () => {
    const indexProduct:number = getProductsInFavorite();
    if (indexProduct === -1) {
      const favorites = state.favorites ? [...state.favorites, props.product] : [props.product];
      dispatch({
        payload: favorites,
        type: 'CURRENT-FAVORITES'
      });
      setIsProductInFavorite(true);
    } else {
      state.favorites.splice(indexProduct, 1);
      dispatch({
        type: 'CURRENT-FAVORITES',
        payload: [...state.favorites]
      });
      setIsProductInFavorite(false);
    }
  }

  const getProductsInFavorite = () => {
    let productIndex = -1;
    if (state.favorites) productIndex = state.favorites.findIndex((favorite:any) => favorite.id === props.product.id);
    return productIndex;
  }

  const getClient = (names:string, numbersA:[string]) => {
    const client = {username: ''};
    const randomIndexNumbers = Math.floor(Math.random() * (numbersA.length));
    const username = `${names} ${numbers[randomIndexNumbers]}`;

    if (username) {
      client.username = username;
      return client;
    }
  }

  const CommentList = ({comments}:{comments:CommentItem[]}) => (
    <List
      dataSource={comments}
      itemLayout='horizontal'
      renderItem={props => <Comment {...props} />}
      header={`${comments.length} ${comments.length > 1 ? intl.formatMessage({id:'MERCHANT_COMMENTS'}) : intl.formatMessage({id:'MERCHANT_COMMENT'})}`}
    />
  );

  const handleSubmit = () => {
    let username:any = getClient(names, numbers);
    if (!valueComment.trim()) return;

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      setValueComment('');
      setComments([
        ...comments,
        {
          author: username.username,
          datetime: moment().fromNow(),
          content: <p>{valueComment.trim()}</p>
        },
      ]);
    }, 500);
  };

  const handleChange = (e:any) => {
    setValueComment(e.target.value);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: intl.formatMessage({id:'MERCHANT_SHARE_PRODUCT_TITLE'}),
        url: window.location.href + `?product=${props.product.id}`,
        text: intl.formatMessage({id:'MERCHANT_SHARE_PRODUCT_TEXT'})
      }).then(() => {
        console.log('Successfully shared');
        navigator.clipboard.writeText(`${window.location.href}?product=${props.product.id}`)
      }).catch(error => {
        console.error('Something went wrong sharing the blog', error);
      });
    }
  };

  let img = (
    <div className={'modal-detail-item-img'}>
      <i
        id={`Cashier-item-img-${props.product.name}`}
        className={'large material-icons Customer-merchant-icon'}
      >loyalty</i>
    </div>
  );

  if (props.product.imgId !== '') {
    img = (
      <Image
        width={265}
        className={'modal-detail-container-img-size'}
        src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${props.product.imgId}`}
      />
    );
  }
  
  const getDiscount = (price:number, discount:any) => {
    let discountToDiv = discount * 100;
    return (100 - Math.floor(discountToDiv / price));
  }

  const Combo = () => {
  let choices: any = []
  props.item.models.map((e:ProductInterface) => {
    e.details.map((f: any)=>{

      if(f.type === 'singleSelection'){

        if(choices.filter((g: any)=>g[0]?.name === f.name).length){
          choices.map((g: any)=>{

            if(g[0].name === f.name && !g.filter((h:any)=>h.product === f.product).length){
              g.push(f)
            };
          })
        }
        else choices.push([f])
      }
    });
  });

    return(
      <>
        {
          choices.map((e: any) => {

            if(e[0].type === 'singleSelection'){
              return(
                <>
                  <Divider />
                  <div className={'modal-detail-container-combos'} onClick={()=>{setShowCombo({...showCombo, [e[0].name] : !showCombo[e[0].name]})}}>
                    <div className={'modal-detail-container-combos-text-container'}>
                      <p className={'modal-detail-container-combos-title'}>{e[0].name==="sideDish" ? intl.formatMessage({id:'MERCHANT_COMBO_TITLE_SIDEDISH'}) : e[0].name==="beverage" && intl.formatMessage({id:'MERCHANT_COMBO_TITLE_BEVERAGE'})}</p>
                      <p className={'modal-detail-container-combos-subtitle'}>{!details[e[0].name] ? intl.formatMessage({id:'MERCHANT_COMBO_REQUIRED'}) : details[e[0].name]}</p>
                    </div>
                    {showCombo[e[0].name]?
                      <UpOutlined className={'modal-detail-container-combos-down'}/>:
                      <DownOutlined className={'modal-detail-container-combos-down'}/>
                    }
                  </div>
                  {
                    showCombo[e[0].name] &&
                    <div  className={'modal-detail-combos-item-container'}>
                      {
                        e.map((f: any) => {
                          return (
                            <div className={'modal-detail-combos-item'} onClick={() => {
                              setDetails({...details, [f.name]: f.product.name});
                              setShowCombo({...showCombo, [f.name]:!showCombo[f.name]})
                            }} >
                              <p className={'modal-detail-combos-item-title'}>{f.product.name}</p>
                              <div className={'modal-detail-combos-circle'} style={{backgroundColor: details[e[0].name] === f.product.name ? '#81B674' : '#FFF'}}/>
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </>
              )
            }
          })
        }
      </>
    )
  }

  const quantity = props.product ? (props.product.quantity - (cart[props.product.id] ? cart[props.product.id] : 0)) : 0;
  let message = !props.hideQuantity
    ? state.currentMerchant.id === 'pumacolombia' ? Number(cart[props.product.id]) > 1 ? Number(cart[props.product.id])  + ' ' + intl.formatMessage({id:'UNITS'}): Number(cart[props.product.id])  + ' ' + intl.formatMessage({id:'UNIT'}):  quantity + (quantity > 0 ? '+' : '') + (quantity == 1 ? intl.formatMessage({id:'CASHIER_ACTIONS__LABEL_AVAILABLE'}) : intl.formatMessage({id:'CASHIER_ACTIONS__LABEL_AVAILABLE_PLURAL'})) : '';
  if (props.product?.quantity === -1) {
    message = ''
  }

  return (
    <Modal
      style={{fontFamily: state.currentMerchant.styles.font, top: 20}}
      visible={props.visible}
      title={props.product.name}
      className={'container-modal'}
      onCancel={() => {props.setShowModal(false)}}
      footer={!cart[props.product?.id] ? ( [<PurchaseProduct key={props.product.id} merchant={state.merchantId} selectingCombo={selectingCombo} hideAvailableStock={false} product={selectedProduct}/>]) : ([<AddAndRemoveProduct key={props.product.id} message={message} product={props.product}/>])}
    >
      <div className={'modal-detail-container'}>
        <div className={'modal-detail-container-img'}>
          {img}
          {(props.product.promo && Object.keys(props.product.promo).length > 0) || props.product.promoPrice && props.product.promoPrice > 0 ?
            <div className={'Modal-Icon-Outlined-Gift'}>
              <Tooltip title={props.product.promo.description}>
                <TagsFilled style={{fontSize: '34px'}}/>
              </Tooltip>
              {state.ruleParams?.promotionIconText &&
                <span className={'Icon-Promotion-Text'}>{props.product.promoPrice && props.product.promoPrice > 0 ? `${getDiscount(props.product.promoPrice, props.product.price)}%` : `${Math.floor(props.product.promo.percent)}%`}</span>
              }
            </div> : <></>
          }
        </div>

        <div>
          <p className={'Modal-Product-Description'}>{props.product.description?.length > 1 ? props.product.description : ''}</p>
          <p style={{fontSize: '12px', fontWeight: 'bold'}}>{state.currentMerchant.styles?.style ? 'Ref.' : `${intl.formatMessage({id:'MERCHANT_ADD_CODE_PRODUCT'})}`}: {props.product.code.length > 1 ? props.product.code : ''}.</p>
          {!state.ruleParams?.hideScoreAndComments && props.product.details.length > 1 ? (props.product.details.map((detail:any, idx:number) => {
            return (
              <div key={idx} className={'modal-detail-details'}>
                <h5 style={{textTransform: 'capitalize'}} key={idx}>{detail.type} - </h5>
                <span>{detail.type === 'color' ? <div className={'modal-detail-circle'} style={{background: detail.value}}/> : <h5 style={{marginLeft: '0.5rem'}}>{detail.value}</h5>}</span>
              </div>
            )})) : ''}

          <div className={'modal-detail-container-rateAndPrice'}>
            <div>
              {!state.ruleParams?.hideScoreAndComments &&
                <span><Rate className={'modal-detail-rate'} onChange={setValue} value={value}/></span>
              }
              {props.product.promoPrice ? <p className={'modal-detail-promoPrice'}>{state.currentMerchant.isoCurrency.symbol} {Utils.formatMoney(props.product.promoPrice, state.ruleParams?.urlStockEnable ? 0 : 2)}</p> : ''}
              <h4>{state.currentMerchant.isoCurrency.symbol} {Utils.formatMoney(props.priceTotal, state.ruleParams?.urlStockEnable ? 0 : 2) || Utils.formatMoney(props.product.price, state.ruleParams?.urlStockEnable ? 0 : 2)}</h4>
            </div>
            <div>
              <div className={isProductInFavorite ? 'modal-favorite-selected' : 'modal-favorite'} onClick={setFavoriteProduct}><HeartOutlined/></div>
              <span className={'modal-favorite'} onClick={handleShare}><ShareAltOutlined/></span>
            </div>
          </div>
          {props.item.models.length > 1 ?
              <Combo /> :
              <></>
          }
        </div>

        {!state.ruleParams?.hideScoreAndComments &&
          <div>
            {comments.length > 0 && <CommentList comments={comments} />}
            <Comment
              content={
                <>
                  <Form.Item>
                    <TextArea
                      rows={4}
                      onSubmit={handleSubmit}
                      onChange={handleChange}
                      value={valueComment}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType='submit'
                      loading={submitting}
                      onClick={handleSubmit}
                      type='primary'
                    >
                      <FormattedMessage id={'MERCHANT_ADD__BUTTON_ADD-COMMENT'}/>
                    </Button>
                  </Form.Item>
                </>
                }/>
          </div>
        }
      </div>
    </Modal>
  )
}