import './AMPMFront/NewProductCard.css';
import {Detail} from './Detail';
import {Details} from './Details';
import {Context} from '../../Store';
import {Carousel, Drawer} from 'antd';
import {ProductCard} from './ProductCard';
import {NewProductCard} from './AMPMFront/NewProductCard';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {MaxProductCard} from './MaxFront/MaxProductCard';

export function ProductItem(props:{
    item:any,
    products:any,
    editing?: boolean,
    merchant?: string,
    isClicking?:boolean,
}) {
    const mapI:any = {};
    const ref:any = useRef();
    const propertiesInterface:any = {};
    const [state, dispatch] = useContext(Context);
    const [mapDetails, setMapDetails] = useState(mapI);
    const [selectedSize, setSelectedSize] = useState<string>("");
    const [selectedColor, setSelectedColor] = useState<string>("");
    const [openDraw, setOpenDraw] = useState(false);
    const [currentPosition, setPosition] = useState(0);
    const [detailModel, setDetailModel] = useState([]);
    const [properties, setProperties] = useState(propertiesInterface);
    const [hiddenProductCardSliders, setHiddenProductCardSliders] = useState(false);

    const handleChange = useCallback(
        newPosition => {

            const newCurrentModel = (props.item['models'].filter((model:any) =>
              model.details.some(
                (detail:any) =>
                  detail.type === 'size' && detail.value === selectedSize ||
                  detail.type === 'color' && detail.value
              )
            )[newPosition]) ? props.item['models'].filter((model:any) =>
              model.details.some(
                (detail:any) =>
                  detail.type === 'size' && detail.value === selectedSize ||
                  detail.type === 'color' && detail.value
              )
            )[newPosition] : '';
            if (newCurrentModel['name'] === props.item.name) {
                const details = newCurrentModel['details'];
                for (const current in details) {
                    const detail = details[current];
                    properties[detail.type] = detail.value;
                }

            }
            properties['color']&&setSelectedColor(properties['color'])
            setProperties(properties);
            setPosition(newPosition);
        },
        [currentPosition, setPosition]
    );

    useEffect(() => {
        getRule();
        
        let details:any = {};
        let detailsStructure:any = {};

        props.item['models'].map((current:any) => {
            for (let currentDetails of current.details) {
                if (currentDetails['type'] === 'color' || currentDetails['type'] === 'size') {
                    if (currentDetails['value']) {

                        if (currentDetails['type'] === 'size'){
                            let colorValue = '';
                            current.details.map((currentDet: any) => {
                                if (currentDet.type === 'color') {
                                    colorValue = currentDet.value;
                                }
                            });

                            if (colorValue != '') {
                                if (detailsStructure[currentDetails['value']]) {
                                    detailsStructure[currentDetails['value']] = [detailsStructure[currentDetails['value']], colorValue];
                                } else {
                                    detailsStructure[currentDetails['value']] = colorValue;
                                }
                            }
                        }

                        let values = [];
                        if (details.hasOwnProperty(current.name)) {
                            if (details[current.name].hasOwnProperty(currentDetails['type'])) {
                                values = details[current.name][currentDetails['type']];
                            }
                        }

                        let existValue = false;
                        for (const currentValue of values) {
                            if (currentValue.value == currentDetails['value']) {
                                existValue = true;
                                break;
                            }
                        }

                        if (!existValue) {
                            values.push(currentDetails);
                        }

                        details[current.name] = Object.assign(details[current.name] || {}, {
                            [currentDetails['type']]: values
                        });
                    }
                }
            }
        });

        for (const current in details[props.item.name]) {
            properties[current] = details[props.item.name][current][0].value;
        }

        if (details[props.item.name] && details[props.item.name].size) {
            if (details[props.item.name].size[0].sort) {
                details[props.item.name].size = details[props.item.name].size.sort((a:any, b:any) => a.sort - b.sort);
            } else {
                details[props.item.name].size = details[props.item.name].size.sort((a:any, b:any) => a.value - b.value);
            }
        }

        if (details[props.item.name] && details[props.item.name].color) {
            details[props.item.name].color = details[props.item.name].color.sort((a:any, b:any) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });
        }

        setDetailModel(detailsStructure);
        setProperties(properties);
        setMapDetails(details);
    }, [props.products]);

    function findMostCommonSize(product:any) {
        let sizes = {};
        let maxCount = 0;
        let mostCommonSize = "";

        product.models.forEach((model:any) => {
            model.details.forEach((detail:any) => {
                if (detail.type === "size") {
                    const size = detail.value;
                    sizes[size] = sizes[size] ? sizes[size] + 1 : 1;

                    if (sizes[size] > maxCount) {
                        maxCount = sizes[size];
                        mostCommonSize = size;
                    }
                }
            });
        });

        return mostCommonSize;
    }

    useEffect(()=>{
        setSelectedSize(findMostCommonSize(props.item))
        setSelectedColor(properties['color'])
    },[])

    const getRule = () => {
        if (state.ruleParams.hiddenProductCardSliders) setHiddenProductCardSliders(true);
    }

    /**
     * Search model with any properties in map to get slide number to jump to
     * @param property
     * @param value
     */
    const jumpToSlide = (property: string, value: string) => {
        setOpenDraw(false)
        setProperties(property);
        if(!property['color']){
            setSelectedSize(value)
        }
        else{
            setSelectedColor(value)
        }
    };

    if (!props.item.models.length) {
        return <></>
    }



    const details = mapDetails.hasOwnProperty(props.item.name) ? (
        <>
            <div className={'Cashier-item-draw-detail'}>
                {Object.keys(properties).map((type:string, index:number) => {
                    let name = '';
                    mapDetails[props.item.name][type]?.map((current: any) => {
                        if (current.value === properties[type] && current.name) {
                            name = current.name;
                        }
                    });
                    return <Detail key={index} type={type} value={properties[type]} name={name} goTo={() => setOpenDraw(true)} selected={false}/>
                })}
            </div>

            <UpOutlined className={'Cashier-item-draw-open'} onClick={() => setOpenDraw(true)}/>

            <Drawer
                closable={false}
                visible={openDraw}
                placement={'bottom'}
                getContainer={false}
                bodyStyle={{
                    margin:0,
                    padding:0,
                    marginTop: '0px',
                    textAlign: 'center',
                    justifyContent: 'space-evenly'
                }}
                className={'Cashier-item-draw'}
                onClose={() => setOpenDraw(false)}
            >
                <DownOutlined className={'Cashier-item-draw-open'} onClick={() => setOpenDraw(false)}/>

                <Details
                    goTo={jumpToSlide}
                    properties={properties}
                    detailModel={detailModel}
                    key={`Details-${props.item.name}`}
                    prototype={mapDetails[props.item.name]}
                    currentKey={`Details-${props.item.name}`}
                />
            </Drawer>
        </>
    ): <></>;
    return (
        <div
            key={'Cashier-item-' + props.item.name}
            onClick={(e: any) => {
                let maskNoClose = true;
                let currentNode = e.target;

                while (currentNode != null && maskNoClose) {
                    const className = (currentNode.className) ? currentNode.className : '';
                    if (typeof className == 'string' && className.includes('draw') || typeof className == 'string' && className.includes('dot')) maskNoClose = false;
                    currentNode = currentNode.parentNode;
                }

                if (openDraw && maskNoClose) setOpenDraw(false);
            }}
            id={`Cashier-item-${props.item.name}`} className={state.currentMerchant.id === 'pumacolombia' ? 'Cashier-item-puma' : (props.editing == true || props.merchant=='belcacr'?'New-product-item':'Cashier-item')}
        >
            <Carousel
              ref={ref}
              effect={'fade'}
              autoplay={false}
              dotPosition={'top'}
              afterChange={handleChange}
              key={'Cashier-item-carousel-' + props.item.name}
              dots={{
                  className: hiddenProductCardSliders || props.editing ? 'Cashier-slider-dots-off' : 'Cashier-slider-dots'
              }}
            >
                {props.item['models'].filter((model) =>
                  model.details.some(
                    (detail) =>
                      detail.type === 'size' && detail.value === selectedSize
                    ||  detail.type === 'color'
                  )
                ).length?
                  props.item['models'] .filter((model) =>
                    model.details.some(
                      (detail) =>
                        detail.type === 'size' && detail.value === selectedSize
                        ||  detail.type === 'color'
                    )
                  ).map((current:any, index:number) => {
                    return (
                      <div key={`Cashier-item-carousel-slide-${current.id}`} className={'Cashier-item-carousel-slide'}>
                          {
                              props.merchant=='belcacr'?
                                <MaxProductCard isClicking={props.isClicking} item={props.item} key={index} prototype={mapDetails[props.item.name]} product={current} properties={properties}/>
                                :props.merchant=='ampm' ?
                                  <NewProductCard isClicking={props.isClicking} item={props.item} key={index} prototype={mapDetails[props.item.name]} product={current} properties={properties}/>
                                  :
                                  <ProductCard item={props.item} key={index} prototype={mapDetails[props.item.name]} product={current} properties={properties}/>
                          }

                          {details}
                      </div>
                    );
                })
                :
              props.item['models'] .map((current:any, index:number) => {
                    return (
                    <div key={`Cashier-item-carousel-slide-${current.id}`} className={'Cashier-item-carousel-slide'}>
                        {
                            props.merchant=='belcacr'?
                            <MaxProductCard isClicking={props.isClicking} item={props.item} key={index} product={current} properties={properties}/>
                            :props.merchant=='ampm' ?
                            <NewProductCard isClicking={props.isClicking} item={props.item} key={index} prototype={mapDetails[props.item.name]} product={current} properties={properties}/>
                            :
                            <ProductCard item={props.item} key={index} prototype={mapDetails[props.item.name]} product={current} properties={properties}/>
                        }

                        {details}
                    </div>
                    );
            })
            }
            </Carousel>
        </div>
    )
}