import React from 'react';
import './Spinner.css';

export function BarsStep(
  props:{
    editing?:boolean,
    merchant?: string
}) {

  return (
      <>{
        props.merchant === 'belcacr' ?
          <div className={'MaxBackground'}><div className={'loader-fb'}/>
        </div>
          : props.merchant === 'ampm' ?
          <div className={'ampmBackground'}><div className={'loader-fb'}/>
          </div>
          :
            <div className={'loader-fb'}/>

      }
      </>

  )
}