import {Reducer} from './Reducer';
import React, {createContext, useReducer} from 'react';

const initialState:any = {
  username:'',
  merchants:[],
  merchantId:'',
  currentMerchant: {
    id: '',
    links: {
      myStore: '#',
      termsUse: '#',
      salesRefund: '#',
      privacyPolicy: '#'
    },
    country: {
      countryCode: 'us'
    },
    isoCurrency: {
      symbol: '$'
    }
  },
  showLoyaltyPoints: false,
  products:{},
  categories:{},
  favorites: [],
  cart: {
    0: {total: 0}
  },
  background: 'start'
};

const useContext = JSON.parse(localStorage.getItem('store') || '{}');
const useState:any = Object.keys(useContext).length ? useContext : initialState

export const Context = createContext(useState);
export const Store = (component: {children: React.ReactNode}) => {
  const [state, dispatch] = useReducer(Reducer, useState);
  return <Context.Provider value={[state, dispatch]}>{component.children}</Context.Provider>;
};
