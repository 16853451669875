import {ShipmentZubaleInterface} from './ShipmentZubaleInterface';

export function ShipmentZubaleValidators(values:ShipmentZubaleInterface):ShipmentZubaleInterface {
  let errors:ShipmentZubaleInterface = {};
  let data : any = values.location

  if (!data.location) {
    errors.location = 'SHIPMENT__FORM_ERROR_LOCATION';
  }
  
  if (!data.isInRange) {
    errors.isInRange = 'SHIPMENT__FORM_ERROR_PICK-A-COVERAGE';
  }
  
  if (!data.idStore) {
    errors.idStore = 'SHIPMENT__FORM_ERROR_PICK-A-COVERAGE';
  }

  if (!data.idType || data.idType.trim() == '') {
    errors.idType = 'SHIPMENT__FORM_ERROR_ID_TYPE';
  }

  if (!data.DNI || data.DNI.trim() == '') {
    errors.DNI = 'SHIPMENT__FORM_ERROR_DNI';
  } else if (data.idType === '01' || data.idType === '02') {
    if (!/^[1-9]-?\d{4}-?\d{4}$/im.test(data.DNI.trim())) {
      errors.DNI = 'SHIPMENT__FORM_INCORRECT_DNI';
    }
  } else if (data.idType === '03') {
    if(!/^[1-9]{1}[0-9]{8}$/im.test(data.DNI.trim())) {
      errors.DNI = 'SHIPMENT__FORM_INCORRECT_DNI';
    }
  } else if (data.idType === '04') {
    if (!/[1-9]{1}[0-9]{10,11}$/im.test(data.DNI.trim())) {
      errors.DNI = 'SHIPMENT__FORM_INCORRECT_DNI';
    }
  }

  if (!data.district || data.district.trim() == '') {
    errors.district = 'SHIPMENT__FORM_ERROR_DISTRICT';
  }

  if (!data.phoneNumber || data.phoneNumber.trim() == '') {
    errors.phoneNumber = 'SHIPMENT__FORM_ERROR_PHONE';
  } else if (data.phoneNumber.length <= 8) {
    errors.phoneNumber =  'SHIPMENT__FORM_INCORRECT_PHONE';
  }

  if (!data.postalCode || data.postalCode.trim() == '') {
    errors.postalCode = 'SHIPMENT__FORM_ERROR_POSTAL_CODE';
  }

  if (!data.address || data.address.trim() == '') {
    errors.address = 'SHIPMENT__FORM_ERROR_ADDRESS';
  } else if (/[!@$%~^*&()_+:"<>;{}?=|/]/im.test(data.address.trim())) {
    errors.address = 'SHIPMENT__FORM_INCORRECT_ADDRESS';
  }

  if (!data.email || data.email.trim() == '') {
    errors.email = 'SHIPMENT__FORM_ERROR_EMAIL';
  } else if (!/^[a-zA-Z0-9._`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/im.test(data.email.trim())) {
    errors.email = 'SHIPMENT__FORM_INCORRECT_EMAIL';
  }

  if (!data.firstName || data.firstName.trim() == '') {
    errors.firstName = 'SHIPMENT__FORM_ERROR_FIRSTNAME';
  } else if (!/^(?:[A-ZÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙ][a-záéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)(\s+[a-áéíóúáÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)?$/im.test(data.firstName.trim())) {
    errors.firstName = 'SHIPMENT__FORM_INCORRECT_FIRSTNAME';
  }

  if (!data.lastName  || data.lastName.trim() == '') {
    errors.lastName = 'SHIPMENT__FORM_ERROR_LASTNAME';
  } else if (!/^(?:[A-ZÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙ][a-záéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)(\s+[a-áéíóúáÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)?$/im.test(data.lastName.trim())) {
    errors.lastName = 'SHIPMENT__FORM_INCORRECT_LASTNAME';
  }

  return errors;
}