import {Shipment} from '../../../../integrations/shipments/zubale/Shipment';

const {Option} = Select;
import './ShowAddress.css';
import {useIntl} from 'react-intl';
import {Select, Button, Switch} from 'antd';
import {Context} from '../../../Store';
import {DownOutlined,UpOutlined, CloseOutlined} from '@ant-design/icons';
import {UseForm} from '../../../../services/Form';
import React, {useContext, useEffect, useState, useRef} from 'react';
import {Utils} from '../../../../services/Utils.class';
import {HttpRequest} from '../../../../services/HttpRequest.class';
import callbacks from '../../../../integrations/shipments/indexCallbacks';
import shipmentsValidators from '../../../../integrations/shipments/indexValidators';

export function ShowAddress(props: {
  reset: any,
  orderId:string,
  orderShipment: any,
  selectedPickup: boolean,
  pickupValues:any,
  addressValues:any,
  setSelectedPickup:Function,
  setSelectedShipment:Function,
  ShipmentHandleChange: Function,
  PickupHandleChange: Function,
  listStores?:any,
  storeId: number,
  getCustomerAddress:Function,
  listAddress?:any,
  stepEnabled:boolean,
  setStoreId:Function,
  setStepEnabled: Function,
  shipmentProvider:string,
}) {
  const intl = useIntl();
  const merchant = Utils.getMerchantByUrl();
  const [state, dispatch] = useContext(Context);
  const [selectValue, setSelectValue] = useState(props.storeId);
  const [newAddress, setNewAddress] = useState(false)
  const [shipmentSwitch, setShipmentSwitch] = useState(false);
  const [storeAlert, setStoreAlert] = useState<any>({});
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const {
    values:shipmentValues,
    errors:shipmentErrors,
    setValues:shipmentSetValues,
    setErrors:shipmentSetErrors,
    handleChange:handleChangeShipment,
    handleSubmit:handleSubmitShipment,
  } = UseForm(shipmentsValidators[props.shipmentProvider], callbacks[props.shipmentProvider], (data:any) => {

  }, 0);

   /** useEffect(()=>{
      if(props.listAddress.length) {
        !newAddress && addressPick(props.listAddress.find((option: any) => option.address.idStore === props.storeId)?.address?.alias);
      }
    }, [newAddress])**/

  const updateOrderPickup = async (data:any) => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    if (Object.keys(data).length) {
      await request.put(props.orderId, data);
    }
  };

  const saveNewAddress = async (event:any) => {
    event.preventDefault()
    let isSave = false;

    const newCustomerAddress:any = {
      merchantId: merchant,
      email: shipmentValues.email,
      lastName: shipmentValues.lastName,
      firstName: shipmentValues.firstName,
      phoneNumber: shipmentValues.phoneNumber,
      address: {
        city: shipmentValues.city,
        state: shipmentValues.state,
        alias: shipmentValues.alias,
        idStore: shipmentValues.idStore,
        address: shipmentValues.address,
        location: shipmentValues.location,
        country: state.currentMerchant.country.countryCode,
        district: shipmentValues.district,
        postalCode: shipmentValues.postalCode
      }
    }

    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const merchantRequest = await request.post(newCustomerAddress, `shipmentAddress/${state.merchantParams.encryptIdCustomer}`, {Accept: 'application/json'});

    if (merchantRequest.data == true && !isSave) {
   await   props.getCustomerAddress()
      Utils.showNotificationMessage('info', intl.formatMessage({id: 'SHIPMENT__ADDRESS_SAVED'}), intl.formatMessage({id: 'SHIPMENT__ADDRESS_SAVE'}));
      isSave = true
    }
    setNewAddress(false)
  };

  useEffect(()=>{
    if(props.listAddress[0]){
      if(props.orderShipment){
        if (props.orderShipment.pickup) {
          storePick(props.storeId);
        } else  {
          addressPick(props.listAddress[props.listAddress.length-1].address.alias)
        }
      }
    else addressPick(props.listAddress[props.listAddress.length-1].address.alias)
    }
    else if(props.orderShipment?.pickup){
      storePick(props.storeId)
    }
    else storePick(18)
  },[props.listAddress])

  useEffect(()=>{
    setSelectValue(props.storeId)
    if(props.selectedPickup){
      setShipmentSwitch(false)
    }
    else{setShipmentSwitch(true)}
  },[])

  const addressPick = (value:any) => {
    if(value==='new'){
      setSelectValue(value)
      setNewAddress(true)
    }
    else {
      const selectedOption = props.listAddress.find((option: any) => option.address.alias == value);
      if (selectedOption) {
        props.setStepEnabled(true);
        const mappedSelectedOption = {
          isInRange: true,
          email: selectedOption.email,
          firstName: selectedOption.name,
          DNI: selectedOption.customerId,
          city: selectedOption.address.city,
          lastName: selectedOption.lastName,
          state: selectedOption.address.state,
          idType: state.merchantParams.idType,
          alias: selectedOption.address.alias,
          phoneNumber: selectedOption.phoneNumber,
          idStore: selectedOption.address.idStore,
          address: selectedOption.address.address,
          location: selectedOption.address.location,
          district: selectedOption.address.district,
          postalCode: selectedOption.address.postalCode
        };

        props.ShipmentHandleChange({
          target: {
            name: 'location',
            value: mappedSelectedOption
          }
        });
        setSelectValue(selectedOption.address.idStore)
        if(Number(props.storeId)!==Number(selectedOption.address.idStore)){
        props.setStoreId(selectedOption.address.idStore);
        }
      }
    }
    setDropdownOpen(false)
  }

  const storePick = (value:any) => {
    const selectedOption = props.listStores.find((option:any) => option.code === value);
    if (selectedOption) {
      setSelectValue(value)
      if(props.storeId!==value){
      props.setStoreId(value);
        props.setStepEnabled(true);
        props.PickupHandleChange({
          target: {
            name: 'pickup',
            value: {
              code: value,
              email : state?.merchantParams.email,
              idType : state?.merchantParams.idType,
              stores : state.currentMerchant?.stores,
              identification : state?.merchantParams.identification,
              firstName : state?.merchantParams.name?.split(' ')[0],
              lastName : state?.merchantParams.name?.split(' ')[1],
            }
          }
        });
        if (state?.merchantParams?.encryptId) {
          props.pickupValues.email = state?.merchantParams.email;
          props.pickupValues.idType = state?.merchantParams.idType;
          props.pickupValues.stores = state.currentMerchant?.stores;
          props.pickupValues.identification = state?.merchantParams.identification;
          props.pickupValues.firstName = state?.merchantParams.name?.split(' ')[0];
          props.pickupValues.lastName = state?.merchantParams.name?.split(' ')[1];
        }
      }
    }
    setDropdownOpen(false)
  }

  const storeHandler = async (type:string, value: any) => {
    dispatch({
      type: 'CLEAN-CART'
    });
    if(type!=="address"){
      dispatch({
        type: 'ADD-CARD',
        payload: {
          "nhlv2g": 1,
        }
        })
      addressPick(value)
    }
    else {
        storePick(value)
    }
    setStoreAlert({})
  }

  const updateOrder = async (data:any) => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    if (Object.keys(data).length) {
      await request.put(props.orderId, data);
    }
  };

  return (
    <div className={'Cashier-header-addressList'}>
      <div className={'Show-Address-header'}>
        <p className={'Show-Address-Title'}>{!shipmentSwitch?'Retirar en ': 'Enviar a '}{!shipmentSwitch?props.listStores.find((option:any) => option.code === selectValue)?.name :!props.addressValues.location?'':props.addressValues.location.alias} </p>
        {dropdownOpen?<UpOutlined className={'Show-Address-dropIcon'}  onClick={() => setDropdownOpen(!dropdownOpen)}/>:<DownOutlined className={'Show-Address-dropIcon'}  onClick={() => setDropdownOpen(!dropdownOpen)}/>}
      </div>
      {
        dropdownOpen?
          <div className={'Show-Address-content'} ref={dropdownRef} >
            <div className={'Show-Address-buttons'}>
              <div className={shipmentSwitch?'Show-Address-button':'Show-Address-button-active'} onClick={()=> {
                setShipmentSwitch(true);
                props.setSelectedPickup(false)
                props.setSelectedShipment(true)
              }}>Envío</div>
              <div className={!shipmentSwitch?'Show-Address-button':'Show-Address-button-active'} onClick={()=> {
                setShipmentSwitch(false);
                props.setSelectedPickup(true)
                props.setSelectedShipment(false)
              }}>Retiro</div>

            </div>
            {
              shipmentSwitch?
                <select
                  defaultValue={'abc'}
                  style={{ maxWidth: '8rem' }}
                  className={'showAddress-select'}
                  onChange={(e)=> {
                    if(e.target.value!=='new') {
                    setStoreAlert({type: '', value: e.target.value});
                  }
                  else addressPick('new')
                  }}
                  >
                    {props.listAddress.map((option: any, idx: number) => (
                      <option className={'showAddress-select-Option'} key={idx} value={option.address.alias}>
                        {option.address.alias}
                      </option>
                    ))
                    }
                    <option className={'showAddress-select-Option-none'} key={20} value={'abc'}>Enviar a:</option>
                    <option className={'showAddress-select-Option'} key={21} value={'new'}>Nueva dirección.</option>
                </select>
                :
                <select
                  style={{ maxWidth: '8rem' }}
                  defaultValue={999}
                  className={'showAddress-select'}
                  onChange={(e)=> {
                    setStoreAlert({type: 'address', value: e.target.value})
                    }
                  }
                  >
                  {props.listStores.sort((a, b) => a.name.localeCompare(b.name)).map((option:any, idx:number) => (
                    <option className={'showAddress-select-Option'} key={idx} value={option.code} >
                      {option.name}
                    </option>
                  ))}
                  <option className={'showAddress-select-Option-none'} key={20} value={999}>Retirar en:</option>
                </select>
                }

          </div>
          :
          <></>
      }
      {
        newAddress ?
          <div className={'shippmentContainer'}>
            <Shipment
              markerIcon={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${state.currentMerchant.logoId}`}
              reset={props.reset}
              errors={shipmentErrors}
              setSelectValue={setSelectValue}
              values={shipmentValues}
              setValues={shipmentSetValues}
              setErrors={shipmentSetErrors}
              handleChange={handleChangeShipment}
              handleSubmit={saveNewAddress}
              setStoreId={props.setStoreId}
              stepEnabled={props.stepEnabled}
              setStepEnabled={props.setStepEnabled}
              addAddress
              setNewAddress={setNewAddress}
            />
          </div>
          :
          <></>
      }
      {storeAlert.value?
        <div className={'Show-Address-storeAlert'}>
          <CloseOutlined className={'Show-Address-storeAlert-close'} onClick={()=>setStoreAlert({})}/>
          <p className={'Show-Address-storeAlert-text'}>{Object.keys(cart).length?'Si cambia la dirección, su carrito se vaciará debido a la disponibilidad de stock de la tienda. ¿Desea continuar?':'Si cambia la dirección, se actualizará la disponibilidad de stock de la tienda. ¿Desea continuar?'}</p>
          <button className={'Show-Address-storeAlert-Button'} onClick={()=>storeHandler(storeAlert.type, storeAlert.value)}>Continuar</button>
        </div>
        :<></>
      }
    </div>
  );
}