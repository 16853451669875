import '../Cashier.css';
import './EditingOrder.css'
import {useHistory} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Breadcrumb, Dropdown, Menu} from 'antd';
import React, {useEffect, useState, useRef} from 'react';
import {useScrollHandler} from '../../../scrollHandler/ScrollHandler';
import {HttpRequest} from '../../../../services/HttpRequest.class';
import {LeftOutlined, SortAscendingOutlined, AppstoreFilled} from '@ant-design/icons';

export function AMPMFilter (props: {
  filters:any,
  order:any,
  products?: any,
  state:any,
  promos:any[],
  category:any,
  lastCategory: string,
  merchant:any,
  visible:boolean,
  idStores?:number,
  editing?:boolean,
  breadcrumbs:any[],
  setOrder:Function,
  changeView:boolean,
  setVisible:Function,
  handleView:Function,
  setProducts:Function,
  setFiltering:Function,
  setCategory?: Function,
  setCategories:Function,
  setBreadcrumbs:Function,
  setShowPromo?: Function,
  categoryFilter:Function,
  setLoadProducts:Function,
  getTreeCategory:Function,
  cleanNavigation?:Function,
  setVirtualPath?: Function,
  updateStateStock?:Function,
  setSearchContent?:Function,
  setFilterContent?:Function,
  setLoadingContent:Function,
  setLastCategory?: Function,
  setLoadCategories:Function,
  setPromotionScreen?: Function,
  productsWithoutCategory:any[],
}) {

  const history = useHistory();
  const [filter, setFilter] = useState('');

  const isMobile = window.innerWidth<950

  const [contentRef, isClicking] =  isMobile
    ? [null, false]:useScrollHandler(useRef<HTMLDivElement>(null))





  useEffect(() => {
    if (props.state.products[props.merchant]) {
      props.setLoadingContent(true);
      let filterArray = [];

      if (props.editing) {
        filterArray = props.products;
      } else {
        getOrderProducts().then((orderProducts) => {
          filterArray = orderProducts;
        })
      }
      if (props.order === 'Mas vendidos') {
        let temp = [...filterArray].sort((a, b) => b.purchases - a.purchases)
        props.setProducts(temp);
      }

      if (props.order === 'Precio desc') {
        let temp = [...filterArray].sort((a, b) => Number(b.models[0].price) - Number(a.models[0].price))
        props.setProducts(temp);
      }

      if (props.order === 'Precio asc') {
        let temp = [...filterArray].sort((a, b) => Number(a.models[0].price) - Number(b.models[0].price))
        props.setProducts(temp);
      }

      if (props.order === 'Alfabético') {
        let temp = [...filterArray].sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        props.setProducts(temp);

      }

      props.setLoadProducts(true);
      props.setLoadingContent(false);
      props.setLoadProducts(false);}



  }, [props.order]);



  useEffect(() => {
    const fetchData = async () => {
      let filterArray = [];
      if (props.editing) {
        props.setLoadingContent(true);
        props.setFilterContent && props.setFilterContent(false)
        props.setProducts([]);
        let FilterId = props.filters.find((f:any) => f.name === filter)?.id;
        const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
        const requestResponse = await request.get(
          `ampmProduct/getByFilter/${props.idStores}/${FilterId}`
        );
        if (requestResponse.status === 200) {
          filterArray = requestResponse.data;
          props.setProducts(filterArray.filter(e=> e.models[0].quantity> 0 && e.models[0].id!=="nhlv2g"));
          props.setLoadingContent(false);
          props.setFilterContent && props.setFilterContent(true)
          props.updateStateStock && props.updateStateStock(filterArray)
        }
      } else {
        filterArray = JSON.parse(JSON.stringify(props.state.products[props.merchant])).filter(
          (e) =>
            props.filters.filter((f:any) => f.name === filter)[0].data.skuIds.includes(Number(e.models[0].code))
        );
      }

      if (filter !== 'Promociones' && filter !== '' && !props.editing) {
        props.setVisible(false);
        props.setLoadProducts(true);
        props.setProducts(filterArray);
      } else if (filter === 'Promociones') {
        props.setPromotionScreen && props.setPromotionScreen(true);
        props.setLoadingContent(false);
      } else props.setLoadingContent(false);
    };

    props.editing && filter?.length && fetchData();
  }, [filter]);



  const getOrderProducts  = async () => {
    const filterProds = JSON.parse(JSON.stringify(props.state.products[props.merchant]));
    let filterArray

    if (props.category) {
      filterArray = props.categoryFilter(filterProds)
    }

    else if (filter !== ''){
      if(props.editing){
        const current = props.filters.filter((f:any) => f.name === filter)[0]
        filterArray = await getFilterProducts(current.id)
      }
      else filterArray = filterProds.filter((e:any) => props.filters.filter((f:any) => f.name === filter)[0].data.skuIds.includes(Number(e.models[0].code)))
    }

    else{
      filterArray = JSON.parse(JSON.stringify(props.state.products[props.merchant]))
    }

    return filterArray
  }

  const getFilterProducts = async(code:string)=>{
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const requestResponse = await request.get(`ampmProduct/searchBar/${props.idStores&&props.idStores}/${code}`);
    if (requestResponse.status === 200) {
      return requestResponse.data
    }
    return []
  }

  const options = (
    <Menu>
      <Menu.Item style={{color: props.order === 'Mas vendidos' ? '#F5222D' : 'black'}} onClick={()=>{props.setOrder('Mas vendidos')}}>
        <FormattedMessage id={'ORDER_FILTER_BEST_SELLING'}/>
      </Menu.Item >
      <Menu.Item style={{color: props.order === 'Alfabético' ? '#F5222D' : 'black'}} onClick={()=>{props.setOrder('Alfabético')}}>
        <FormattedMessage id={'ORDER_FILTER_ALPHABETICAL'}/>
      </Menu.Item>
      <Menu.Item style={{color: props.order === 'Precio asc' ? '#F5222D' : 'black'}} onClick={()=>{props.setOrder('Precio asc')}}>
        <FormattedMessage id={'ORDER_FILTER_LOWER_PRICE'}/>
      </Menu.Item>
      <Menu.Item style={{color: props.order === 'Precio desc' ? '#F5222D' : 'black'}} onClick={()=>{props.setOrder('Precio desc')}}>
        <FormattedMessage id={'ORDER_FILTER_HIGHEST_PRICE'}/>
      </Menu.Item>
    </Menu>
  )

  return(
    <div className={props.editing ? 'Cashier-breadcrumb-home-content-editing' : 'Cashier-breadcrumb-home-content'}>
      <div className={props.editing ? 'Editing-breadcrumb-left-container' : 'Cashier-breadcrumb-left-container'}>
        {props.category?.length?
          <Breadcrumb>
            <Breadcrumb.Item
              key={'/0'}
              onClick={() => {
                props.setShowPromo && props.setShowPromo(false);
                props.setFilterContent && props.setFilterContent(false);
                setFilter('');

                if (props.editing && props.setCategory && props.setLastCategory) {
                  props.setSearchContent && props.setSearchContent(false);
                  props.setLoadingContent(false);
                  props.setVirtualPath && props.setVirtualPath('');
                  props.setBreadcrumbs([]);
                  props.setProducts([])
                  props.setCategory('');
                  props.setLastCategory('');
                } else {
                  props.setVisible(false);
                  props.setBreadcrumbs([]);
                  props.cleanNavigation(true);
                  history.push('/0');
                }
              }}
            >
            <span style={{
              fontFamily: props.state.currentMerchant.styles.font,
              color: `${props.state.currentMerchant.styles?.color1}`
            }} className={'Cashier-breadcrumb-span'}>Inicio</span>
            </Breadcrumb.Item>
            {props.breadcrumbs}
          </Breadcrumb>
          :
          filter?.length || props.products?.length ?
            <Breadcrumb>
              <Breadcrumb.Item
                key={'/0'}
                onClick={() => {
                  props.setShowPromo && props.setShowPromo(false);
                  props.setFilterContent && props.setFilterContent(false);
                  setFilter('');

                  if (props.editing && props.setCategory && props.setLastCategory) {
                    props.setSearchContent && props.setSearchContent(false);
                    props.setLoadingContent(false);
                    props.setVirtualPath && props.setVirtualPath('');
                    props.setBreadcrumbs([]);
                    props.setCategory('');
                    props.setLastCategory('');
                    props.setProducts([])
                  } else {
                    props.setVisible(false);
                    props.setBreadcrumbs([]);
                    props.cleanNavigation(true);
                    history.push('/0');
                  }
                }}
              >
                <LeftOutlined
                  className={props.editing ? 'editing-breadcrumb-home-icon' : 'Cashier-breadcrumb-home-icon'}/>
              </Breadcrumb.Item>

              {props.breadcrumbs}
            </Breadcrumb>
            : <></>}
        {!props.category && isMobile?
          <div className={'filterTabsContainer'}>
            <div className={props.editing?filter.length?'Editing-Circle-Button-mobile-Container':'Editing-Circle-Button-mobile-Container1':'Filter-Circle-Button-Container'}>
              {props.filters?.length ?
                props.filters.sort((a: any, b: any) => b.sort - a.sort).map((current: any, index: number) => {
                  return <div
                    className={filter === current.name ? (props.editing?'Editing-Circle-Button-Selected':'Filter-Circle-Button-Selected') : (props.editing?'Editing-Circle-Button':'Filter-Circle-Button')}
                    key={index}
                    onClick={() => {
                      setFilter(current.name)
                    }}
                  >
                    <p className={props.editing?'Filter-Text':''}>{current.name}</p>
                  </div>
                })
                :
                <></>}
            </div>
          </div>
          : !props.category&&
          <div >
            <div className={props.editing?'Editing-Circle-Button-Container':'Filter-Circle-Button-Container'} ref={contentRef}>
              {props.filters?.length ?
                props.filters.sort((a: any, b: any) => b.sort - a.sort).map((current: any, index:number) => {
                  return <div
                    className={filter === current.name ? (props.editing?'Editing-Circle-Button-Selected':'Filter-Circle-Button-Selected') : (props.editing?'Editing-Circle-Button':'Filter-Circle-Button')}
                    key={index}
                    onClick={() => {
                      !isClicking && setFilter(current.name)
                    }}
                  >
                    <p className={props.editing?'Filter-Text':''}>{current.name}</p>
                  </div>
                })
                :
                <></>}
            </div>
          </div>
        }
      </div>
      <div className={'Cashier-container-promoAndFilter'}>
        {!props.editing?<AppstoreFilled style={{
          display: filter !== '' && filter === 'Promociones' || props.visible ? 'initial' : 'none',
          fontSize: '1.8rem',
          color: !props.changeView ? '#8C8C8C' : '#7CB305'
        }} onClick={() => props.handleView()}/>:<></>}
        <Dropdown overlay={options} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {
              props.products && props.products.length || filter.length ?  <SortAscendingOutlined className={'Sort-Icon'}/> : <></>
            }
          </a>
        </Dropdown>
      </div>
    </div>
  )
}