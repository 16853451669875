import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import React from 'react';
import customMap from './CustomMap';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import { useState, useEffect } from 'react';
import { HttpRequest } from '../../services/HttpRequest.class';
import { Utils } from '../../services/Utils.class';
import * as HttpStatus from 'http-status-codes';

export function AMPMMap(props: {
  state?: any,
  marker?: any,
  search?: any,
  zoom?: number,
  valueLocation?: any,
  setAddress: Function,
  getStoreId: Function,
  getPolygons: Function,
  handleChange: Function,
  setUserInRange: Function,
}) {
  let polygons: google.maps.Polygon[] = [];
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const [userLocation, setUserLocation] = useState({
    lat: Number(props.state?.location?.lat || 9.9356284),
    lng: Number(props.state?.location?.long || -84.1483648)
  });

  useEffect(() => {
    if (props.valueLocation) {
      setUserLocation({ lat: props.valueLocation.lat, lng: props.valueLocation.lng });
    }
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }, []);

  function showPosition(position: any) {
    setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
    props.handleChange({
      target: {
        name: 'location',
        value: userLocation
      }
    });
  }


  const apiGoogleOnLoad = async (map: google.maps.Map<Element>) => {
    await drawPolygons(map);
    await initAutocomplete(map);
  }

  const initAutocomplete = async (map: google.maps.Map<Element>) => {
    if (!props.search.current) return;

    const option = {
      fields: ['address_component', 'geometry'],
      componentRestrictions: { country: props.state.country.countryCode },
      types: ['address'],
    }

    let marker = await userMarker(map, userLocation);
    marker.setMap(map);
    getLocationByMarker(map, marker);

    const googleMaps = window.google.maps;
    if (googleMaps) {
      const autocomplete = new googleMaps.places.Autocomplete(props.search.current, option);

      autocomplete.addListener('place_changed', () => {
        onChangeAddress(autocomplete);
        marker.setPosition(userLocation);
        map.setCenter(userLocation);
        marker.getDraggable();
        map.setZoom(15);
      });
    };
  }


  const getCoordsByCountry = async () => {
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const requestResponse = await request.get(`shipment/coordinates/${props.state.country.countryCode}`, {}, { 'Accept': 'application/json' });

    if (requestResponse.status === HttpStatus.OK) {
      return requestResponse.data;
    }

    return null;
  }

  const drawPolygons = async (maps: any) => {
    const dataPolygons = await props.getPolygons();

    dataPolygons.forEach((polygonCoords: any) => {
      const polygonPath = polygonCoords.map((coord: any) => ({
        lat: parseFloat(coord.lat),
        lng: parseFloat(coord.lng)
      }));

      const polygon = new google.maps.Polygon({
        paths: polygonPath,
        strokeWeight: 1,
        fillOpacity: 0.3,
        strokeOpacity: 0.6,
        fillColor: "#1890ff",
        strokeColor: "#0883ff",
      });
      polygons = dataPolygons
      polygon.setMap(maps);
    });
  }


  const extractAddress = (place: google.maps.places.PlaceResult) => {
    const address = {
      city: '',
      state: '',
      country: '',
      district: '',
      postalCode: '',
    }

    if (!Array.isArray(place?.address_components)) return address;

    props.setAddress(place.formatted_address);

    place.address_components.forEach((component: any) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes('locality')) address.district = value;

      if (types.includes('country')) address.country = value;

      if (types.includes('administrative_area_level_1')) address.state = value;

      if (types.includes('postal_code')) { address.postalCode = value; } else address.postalCode = '10101';

      if (types.includes('administrative_area_level_2')) { address.city = value; } else address.city = 'San josé';
    });

    props.handleChange({
      target: {
        name: 'city',
        value: address.city
      }
    });

    props.handleChange({
      target: {
        name: 'state',
        value: address.state
      }
    });

    props.handleChange({
      target: {
        name: 'country',
        value: address.country
      }
    });

    props.handleChange({
      target: {
        name: 'district',
        value: address.district
      }
    });

    props.handleChange({
      target: {
        name: 'postalCode',
        value: address.postalCode
      }
    });
  }

  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();

    calculateDistance({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });

    userLocation.lat = place.geometry.location.lat();
    userLocation.lng = place.geometry.location.lng();

    extractAddress(place);
    props.handleChange({
      target: {
        name: 'location',
        value: userLocation
      }
    });

    checkUserIsInArea(polygons, userLocation);
    props.getStoreId(userLocation);
  }

  const userMarker = async (maps: any, locationUser: any) => {
    return new google.maps.Marker({
      draggable: true,
      position: locationUser,
      animation: google.maps.Animation.DROP,

    });
  };

  const getLocationByMarker = (map: any, marker: google.maps.Marker) => {
    let maps = window.google.maps
    maps.event.addListener(marker, 'dragend', () => {
      calculateDistance({ lat: marker.getPosition().lat(), lng: marker.getPosition().lng() });

      userLocation.lat = marker.getPosition().lat();
      userLocation.lng = marker.getPosition().lng();
      reverseGeoCode(userLocation);
      props.getStoreId(userLocation);

      checkUserIsInArea(polygons, userLocation);
      props.handleChange({
        target: {
          name: 'location',
          value: userLocation
        }
      });
    });
  }

  const checkUserIsInArea = async (areas: google.maps.Polygon[], pathCoordinates: google.maps.LatLngLiteral) => {
    let isInRange = false;

    for (const area of areas) {
      const polygon = new google.maps.Polygon({
        paths: area
      });
      if (google.maps.geometry.poly.containsLocation(pathCoordinates, polygon)) {
        isInRange = true;
        break;
      }
    }

    if (!isInRange) {
      Utils.showNotificationMessage('info', intl.formatMessage({ id: 'SHIPMENT__FORM_ERROR_OUT-OF-RANGE' }), intl.formatMessage({ id: 'SHIPMENT__FORM_ERROR_PICK-A-COVERAGE' }));
    }

    props.setUserInRange(isInRange);
  }

  const reverseGeoCode = async (location: google.maps.LatLngLiteral) => {
    const request = new HttpRequest('https://maps.googleapis.com' || '');
    const requestResponse = await request.get(`maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyA8FcoNMaYnK7jzTxxdGWvN8YovLjwIG1k`, {}, { 'Accept': 'application/json' });

    if (requestResponse.status === HttpStatus.OK) {
      return extractAddress(requestResponse.data.results[0])
    }
  }

  const calculateDistance = (newPosition: google.maps.LatLngLiteral) => {
    const pointCustomerA = new google.maps.LatLng(userLocation);
    const pointCustomerB = new google.maps.LatLng(newPosition);

    const distance = google.maps.geometry.spherical.computeDistanceBetween(pointCustomerA, pointCustomerB);

    if (distance > 1000) {
      //  if (!visible) setVisible(true);
    }
  }

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    //setUserLocation(lastLocation);
    //reverseGeoCode(userLocation);
    // marker.setPosition(userLocation);
  };

  return (
    <>
      <div>
        <Modal
          onOk={handleOk}
          visible={visible}
          onCancel={handleCancel}
        >
          <p>¿Esta dirección se encuentra lejos, deseas seleccionar igualmente?</p>
        </Modal>
      </div>

      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={userLocation}
        zoom={props.zoom || 9}
        onLoad={(map) =>apiGoogleOnLoad(map)}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Polygon
          paths={[]}
          options={{
            strokeWeight: 1,
            fillOpacity: 0.15,
            strokeOpacity: 0.3,
            fillColor: "#1890ff",
            strokeColor: "#0883ff",
          }}
        />
      </GoogleMap>

    </>
  )
}
