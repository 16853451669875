import './AMPMPurchaseProduct.css';
import {useIntl} from 'react-intl';
import {Context} from '../../Store';
import React, {useContext} from 'react';
import {AMPMAddAndRemoveProduct} from './AMPMAddAndRemoveProduct';

export function AMPMPurchaseProduct (props:{
  product:any,
  selectingCombo?:any,
  hideAvailableStock:boolean
}) {
  const intl = useIntl();
  const [state, dispatch] = useContext(Context);
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  if (!cart[props.product?.id]) {
    return (
      <div style={{fontFamily:state.currentMerchant.styles.font, backgroundColor:`${state.currentMerchant.styles.color2}`}} className={
        ((!props.product?.quantity || props.product?.price == 0 || props.selectingCombo)?`New-product-Purchase-unn`:`New-product-Purchase`)}
           onClick={props.product?.quantity != 0 && props.product?.price != 0 && props.product?.id ? () => dispatch({
             type: 'ADD-CARD',
             payload: {
               [props.product.id]: 1
             }
           }) : ()=>false}>
        <span className={'Cashier-item-withOutStock'}>{props.product?.quantity && props.product?.price != 0 ? intl.formatMessage({id:'CASHIER_ACTIONS__BUTTON_ADD_CART'}) : !props.product ? intl.formatMessage({id:'MERCHANT_COMBO_REQUIRED'}) : intl.formatMessage({id:'CASHIER_ACTIONS__BUTTON_SOLDOUT'})}</span>
      </div>
    )
  }

  const quantity = props.product ? (props.product.quantity - (cart[props.product.id] ? cart[props.product.id] : 0)) : 0;
  let message = !props.hideAvailableStock
    ?
    quantity===0? 'Agotado':'':'';
  if (props.product?.quantity === -1) {
    message = ''
  }

  return <AMPMAddAndRemoveProduct product={props.product} message={message}/>;
}