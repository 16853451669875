import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Detail } from './Detail';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';

export function Details(props) {
    const intl = useIntl();
    const [showAllSizes, setShowAllSizes] = useState(false);
    const handleSizeClick = (detail) => {


        if (!props.disabled) {
            props.goTo && props.goTo({[ detail.type ]: detail.value}, detail.value);
            setShowAllSizes(false);

        } else {
            setShowAllSizes(true);
        }
        ;
    }

    const renderDetail = (detail, index) => {

        return (
            <Detail
                key={index}
                type={detail.type}
                name={detail.name}
                value={detail.value}
                selected={props.properties[detail.type] === detail.value}
                disabled={detail.disabled}
                setShowAllSizes={setShowAllSizes}
                showAllSizes={showAllSizes}
                goTo={props.goTo}
            />
        );
    };

    return (
      <div className={'Cashier-item-draw-details-content'}>
          {Object.keys(props.prototype).map((property,i) => {
              if (property === 'size') {
                  const selectedIndex = props.prototype[property].findIndex(detail => props.properties[property] === detail.value);
                  const selectedSizeDetail = props.prototype[property][selectedIndex];
                  const prevSizeDetail = selectedIndex > 0 ? props.prototype[property][selectedIndex - 1] : props.prototype[property][props.prototype[property].length-1];
                  const nextSizeDetail = selectedIndex < props.prototype[property].length - 1 ? props.prototype[property][selectedIndex + 1] : props.prototype[property][0];

                  return (
                    <div key={`${props.currentKey}-size`} className={'Cashier-item-draw-details'}>
                        {showAllSizes ?
                          props.prototype[property].sort((a, b) => parseFloat(a.value) - parseFloat(b.value)).map(renderDetail)
                          :<div className={'Cashier-Item-sizeContainer'}>
                          <LeftOutlined className={'Cashier-Item-sizeArrow'} onClick={()=>handleSizeClick(prevSizeDetail)} />
                        {renderDetail(selectedSizeDetail, 0)}
                            <RightOutlined  className={'Cashier-Item-sizeArrow'} onClick={()=>handleSizeClick(nextSizeDetail)} />
                          </div>

                        }
                    </div>
                  );
              }
              // Render other properties as needed
              return null;
          })}
      </div>
    );
}
