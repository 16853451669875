import './ShipmentCR.css';
import {Form, Input, Select} from 'antd';
import {states} from './territoryCR.json';
import PhoneInput from 'react-phone-input-2';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

export function ShipmentCR(props:{
  values:any,
  errors:any,
  reset:boolean,
  handleSubmit:any,
  setValues:Function,
  setErrors:Function,
  stepEnabled:boolean,
  handleChange:Function,
  setStepEnabled:Function
}) {
  const intl = useIntl();
  const {Option} = Select;
  const [state, setState] = useState();
  const [city, setCity]:any = useState();
  const [postal, setPostalCode]:any = useState();
  const [district, setDistrict]:any = useState();
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);

  useEffect(() => {
    if (!Object.keys(props.errors).length) validateEnabledStep();
  }, [props.errors]);

  useEffect(() => {
    if (props.reset && !props.stepEnabled) {
      props.setValues({});
      props.setErrors({});
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.values.city) {
      loadCities(props.values.state);
      const stateIndex = states.findIndex((state) => state.value === props.values.state);
      loadDistricts(stateIndex, props.values.city);
      loadPostalCode(props.values.district);
    }
  }, []);

  const onChangeState = (value:any) => {
    setState(value);

    if (props.values.city || city) {
      props.values.city = '';
      setCity(intl.formatMessage({id: 'SHIPMENT__FORM_CANTON'}));
    }

    if (props.values.district || district) {
      setDistricts([]);
      setPostalCodes([]);
      props.values.district = '';
      props.values.postalCode = '';
      setDistrict(intl.formatMessage({id: 'SHIPMENT__FORM_DISTRICT'}));
      setPostalCode(intl.formatMessage({id: 'SHIPMENT__FORM_POSTAL_CODE'}));
    }

    if (props.stepEnabled) props.setStepEnabled(false);

    props.handleChange({
      target: {
        value: value,
        name: 'state'
      }
    });

    loadCities(value);
  }

  const onChangeCity = (value:any) => {
    setCity(value);
    const stateIndex = states.findIndex((state) => state.value === props.values.state);

    if (props.values.district || district) {
      setPostalCodes([]);
      props.values.district = '';
      props.values.postalCode = '';
      setDistrict(intl.formatMessage({id: 'SHIPMENT__FORM_DISTRICT'}));
      setPostalCode(intl.formatMessage({id: 'SHIPMENT__FORM_POSTAL_CODE'}));
    }

    if (props.stepEnabled) props.setStepEnabled(false);

    props.handleChange({
      target: {
        name: 'city',
        value: value
      }
    });

    loadDistricts(stateIndex, value);
  }

  const onChangeDistrict = (value:any) => {
    setDistrict(value);
    props.handleChange({
      target: {
        value: value,
        name: 'district'
      }
    });

    loadPostalCode(value);
  }

  const onChangePostalCode = (value:any) => {
    setPostalCode(value);
    props.handleChange({
      target: {
        value: value,
        name: 'postalCode'
      }
    });
  }

  const loadCities = (selectedState:string) => {
    const stateData:any = states.filter((state) => { return state.value === selectedState; });
    setCities(stateData[0].cities);
  }

  const loadDistricts = (stateIndex:number, selectedCity:string) => {
    const cityData:any = states[stateIndex].cities.filter((city) => { return city.value === selectedCity; });
    setDistricts(cityData[0].districts);
  }

  const loadPostalCode = (selectedDistrict:string) => {
    const stateIndex = states.findIndex((state) => state.value === props.values.state);
    const cityIndex = states[stateIndex].cities.findIndex((city) => city.value === props.values.city);
    const districtData:any = states[stateIndex].cities[cityIndex].districts.filter((district) => district.value === selectedDistrict);

    onChangePostalCode(districtData[0].postalCode);
    setPostalCodes(districtData[0].postalCode);
  }

  const validateEnabledStep = () => {
    const enabled = !Object.keys(props.errors).length;
    if (!props.stepEnabled && enabled && Object.keys(props.values).length) props.setStepEnabled(true);
    if (props.stepEnabled && (!enabled || !Object.keys(props.values).length)) props.setStepEnabled(false);
  }

  return (
    <form className={'Form shipment-form'}>
      <Form.Item>
        <Input
          maxLength={30}
          name={'firstName'}
          value={props.values.firstName}
          onChange={(event:any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_FIRSTNAME'})}
          style={{color:`${props.values.currentMerchant?.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.firstName && 'shipment-form-input__error'}`}
        />

        {props.errors.firstName && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.firstName}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          maxLength={30}
          name={'lastName'}
          value={props.values.lastName}
          onChange={(event:any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_LASTNAME'})}
          style={{color:`${props.values.currentMerchant?.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.lastName && 'shipment-form-input__error'}`}
        />

        {props.errors.lastName && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.lastName}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          name={'DNI'}
          maxLength={11}
          value={props.values.DNI}
          onChange={(event:any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_DNI'})}
          style={{color:`${props.values.currentMerchant?.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.DNI && 'shipment-form-input__error'}`}
        />

        {props.errors.DNI && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.DNI}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Select
          showSearch
          onChange={onChangeState}
          optionFilterProp={'children'}
          value={props.values.state || state}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_PROVINCE'})}
          className={`shipment-form-inputSelect ${props.errors.state && 'shipment-form-input__error'}`}
          filterOption={(input, option:any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >

          {states.map((state:any, index:number) => {
            return <Option key={index} value={state.value}>{state.value}</Option>
          })}
        </Select>

        {props.errors.state && (
          <span className={'Form-input-error input-error'}>{<FormattedMessage id={props.errors.state}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Select
          showSearch
          onChange={onChangeCity}
          optionFilterProp={'children'}
          value={props.values.city || city}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_CANTON'})}
          className={`shipment-form-inputSelect ${props.errors.city && 'shipment-form-input__error'}`}
          filterOption={(input, option:any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >

          {cities.length > 0 && cities.map((city:any, index:number) => {
            return <Option key={index} value={city.value}>{city.value}</Option>
          })}
        </Select>

        {props.errors.city && (
          <span className={'Form-input-error input-error'}>{<FormattedMessage id={props.errors.city}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Select
          showSearch
          onChange={onChangeDistrict}
          optionFilterProp={'children'}
          value={props.values.district || district}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_DISTRICT'})}
          className={`shipment-form-inputSelect ${props.errors.district && 'shipment-form-input__error'}`}
          filterOption={(input, option:any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >

          {districts.length > 0 && districts.map((district:any, index:number) => {
            return <Option key={index} value={district.value}>{district.value}</Option>
          })}
        </Select>

        {props.errors.district && (
          <span className={'Form-input-error input-error'}>{<FormattedMessage id={props.errors.district}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Select
          showSearch
          onChange={onChangePostalCode}
          optionFilterProp={'children'}
          defaultActiveFirstOption={true}
          value={props.values.postalCode || postal}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_POSTAL_CODE'})}
          className={`shipment-form-inputSelect ${props.errors.postalCode && 'shipment-form-input__error'}`}
          filterOption={(input, option:any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >

          {postalCodes.length > 0 && districts.map((district:any, index:number) => {
            return <Option key={index} value={district.postalCode}>{district.postalCode}</Option>
          })}
        </Select>

        {props.errors.postalCode && (
          <span className={'Form-input-error input-error'}>{<FormattedMessage id={props.errors.postalCode}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          maxLength={60}
          name={'address'}
          value={props.values.address}
          onChange={(event:any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_ADDRESS'})}
          style={{color:`${props.values.currentMerchant?.styles.color1}`}}
          className={`shipment-form-input ${props.errors.address && 'shipment-form-input__error'}`}
        />

        {props.errors.address && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.address}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <div style={{color:`${props.values.currentMerchant?.styles.color1}`}} className={`shipment-form-input-phone ${props.errors.phoneNumber && 'shipment-form-input__error'}`}>
          <PhoneInput
            country={'cr'}
            placeholder={'+506 8588-0000'}
            inputClass={`Form-phone-input`}
            value={props.values.phoneNumber}
            inputProps={{name: 'phoneNumber'}}
            onChange={(values:any, country:any, event:any) => {
              event.persist();
              if (event.target) {
                props.values['country'] = country;
                props.handleChange(event);
              }
            }}
          />
        </div>

        {props.errors.phoneNumber && (
          <span className={'Form-input-error input-error-phone'}>{<FormattedMessage id={props.errors.phoneNumber}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          name={'email'}
          maxLength={30}
          value={props.values.email}
          onChange={(event:any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_EMAIL'})}
          style={{color:`${props.values.currentMerchant?.styles.color1}`}}
          className={`shipment-form-input ${props.errors.email && 'shipment-form-input__error'}`}
        />

        {props.errors.email && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.email}/>}</span>
        )}
      </Form.Item>
    </form>
  )
}