export default (shipmentValues:any, shipmentErrors:any, updateOrder:any = (data:any) => {}, current:number) => {
  if (!Object.keys(shipmentErrors).length && current) {
    let addressData = {};
    let values : any = shipmentValues.location
    let clientProfileData = {email: values.email.trim()};

    if (values.DNI) {
      clientProfileData = Object.assign(clientProfileData, {
        DNI: values.DNI,
        phoneNumber: values.phoneNumber,
        lastName: values.lastName.trim(),
        firstName: values.firstName.trim()
      });
    }
    
    if (values.id) addressData = {id: values.id};
    
    if (values.address) {
      addressData = Object.assign(addressData, {
        city: values.city,
        state: values.state,
        idStore: values.idStore,
        district: values.district,
        location: values.location,
        postalCode: values.postalCode,
        address: values.address.trim()
      });
    }
    
    const shipmentData = {
      delivered: {
        shipment: {
          address: addressData,
          clientProfile: clientProfileData
        }
      }
    };
    
    updateOrder(shipmentData);
  }
}
