import '../shipmentCO/ShipmentCO.css';
import {Input, Form, Select} from 'antd';
import {states} from './territoryCO.json';
import PhoneInput from 'react-phone-input-2';
import * as HttpStatus from 'http-status-codes';
import {Context} from '../../../components/Store';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext, useEffect, useState} from 'react';
import {HttpRequest} from '../../../services/HttpRequest.class';

export function ShipmentCO (props: {
  values: any,
  errors: any,
  reset: boolean,
  markerIcon: any,
  handleSubmit: any,
  setValues: Function,
  setErrors: Function,
  stepEnabled: boolean,
  handleChange: Function,
  setStepEnabled: Function
}) {
  const intl = useIntl();
  const [stateContext, dispatch] = useContext(Context);
  const {Option} = Select;
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (!Object.keys(props.errors).length) validateEnabledStep();
  }, [props.errors]);


  useEffect(() => {
    if (stateContext.merchantParams?.encryptId) {
      getDecipherInfo().then();
    }
  }, []);

  useEffect(() => {
    if (props.reset && !props.stepEnabled) {
      props.setErrors({});
      props.setValues({});
    }
  }, [props.reset]);

  const getDecipherInfo = async () => {
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const decipherDataRequest: any = await request.get(`encrypt/${stateContext.merchantParams.encryptId}`);

    if (decipherDataRequest.status === HttpStatus.OK) {
      const decipherData = JSON.parse(decipherDataRequest.data.decipherData);

      if (decipherData.encryptId) {
        setMerchantParams({
          encryptId: decipherData.encryptId
        });
      }
    }
  };

  const setMerchantParams = (params: object) => {
    dispatch({
      type: 'ADD-MERCHANT-PARAMS',
      payload: params
    });
  };

  if (stateContext?.merchantParams?.encryptId) {
    props.values.email = stateContext?.merchantParams.email;
    props.values.idType = stateContext?.merchantParams.idType;
    props.values.DNI = stateContext?.merchantParams.identification;
    props.values.firstName = stateContext?.merchantParams.name.split(' ')[0];
    props.values.lastName = stateContext?.merchantParams.name.split(' ')[1];
  }

  const validateEnabledStep = () => {
    const enabled = !Object.keys(props.errors).length;
    if (!props.stepEnabled && enabled && Object.keys(props.values).length) props.setStepEnabled(true);
    if (props.stepEnabled && (!enabled || !Object.keys(props.values).length)) props.setStepEnabled(false);
  };

  useEffect(() => {
    if (props.values.state) {
      const checkStateExists = states.filter((ele: any) => ele.value == props.values.state);
      if (checkStateExists.length) {
        onChangeState(props.values.state);
      } else {
        props.values.state = '';
      }
    }
  }, [props.values.state]);

  useEffect(() => {
    if (props.values.city) {
      const checkCityExists = cities.filter((ele: any) => ele.value == props.values.city);
      if (checkCityExists.length) {
        onChangeCity(props.values.city);
      } else {
        props.values.city = '';
      }
    }
  }, [props.values.city]);

  const onChangeState = (value:any) => {
    setState(value);
    if (props.values.state) {
      props.values.state = '';
    }
    if (props.stepEnabled) props.setStepEnabled(false);
    props.handleChange({
      target: {
        value: value,
        name: 'state'
      }
    });
    loadCities(value);
  };

  const onChangeCity = (value: any) => {
    setCity(value);
    if (props.values.city) {
      props.values.city = '';
    }
    if (props.stepEnabled) props.setStepEnabled(false);
    props.handleChange({
      target: {
        name: 'city',
        value: value
      }
    });
  };

  const loadCities = (selectedState: string) => {
    const stateData: any = states.filter((state) => {
      return state.value === selectedState;
    });
    setCities(stateData[0].cities);

    if (props.values.city) onChangeCity(props.values.city);
  };

  return (
    <form className={'Form-shipmentCO pickup-form'}>
      <Form.Item>
        <Input
          maxLength={60}
          name={'address'}
          value={props.values.address}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_CO_ADDRESS'})}
          onChange={(event: any) => props.handleChange(event)}
          style={{color: `${stateContext.currentMerchant.styles?.color1}`}}
          className={`shipment-form-input-margin ${props.errors.address && 'shipment-form-input__error'}`}
        />

        {props.errors.address_reference && (
          <span className={'Form-input-error'}>{<FormattedMessage
            id={props.errors.address}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Select
          showSearch
          onChange={onChangeState}
          optionFilterProp={'children'}
          value={props.values.state || state}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_DEPARTMENT'})}
          className={`shipment-form-inputSelect ${props.errors.state && 'shipment-form-input__error'}`}
          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >

          {states.map((state: any, index: number) => {
            return <Option key={index} value={state.value}>{state.value}</Option>;
          })}
        </Select>

        {props.errors.state && (
          <span className={'Form-input-error input-error'}>{<FormattedMessage
            id={props.errors.state}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Select
          showSearch
          onChange={onChangeCity}
          optionFilterProp={'children'}
          value={props.values.city || city}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_CITY'})}
          className={`shipment-form-inputSelect ${props.errors.city && 'shipment-form-input__error'}`}
          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >

          {cities.length > 0 && cities.map((city: any, index: number) => {
            return <Option key={index} value={city.value}>{city.value}</Option>;
          })}
        </Select>

        {props.errors.city && (
          <span className={'Form-input-error input-error'}>{<FormattedMessage id={props.errors.city}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          maxLength={30}
          name={'firstName'}
          value={props.values.firstName}
          onChange={(event: any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_FIRSTNAME'})}
          style={{color: `${stateContext.currentMerchant.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.firstName && 'shipment-form-input__error'}`}
        />

        {props.errors.firstName && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.firstName}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          maxLength={30}
          name={'lastName'}
          value={props.values.lastName}
          onChange={(event: any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_LASTNAME'})}
          style={{color: `${stateContext.currentMerchant.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.lastName && 'shipment-form-input__error'}`}
        />

        {props.errors.lastName && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.lastName}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <Input
          name={'DNI'}
          maxLength={11}
          value={props.values.DNI}
          onChange={(event: any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_DNI'})}
          style={{color: `${stateContext.currentMerchant.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.DNI && 'shipment-form-input__error'}`}
        />

        {props.errors.DNI && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.DNI}/>}</span>
        )}
      </Form.Item>

      <Form.Item>
        <div
          style={{color: `${stateContext.currentMerchant.styles?.color1}`}} className={`shipment-form-input-phone ${props.errors.phoneNumber && 'shipment-form-input__error'}`}>
          <PhoneInput
            country={'co'}
            placeholder={'+57 8588-0000'}
            inputClass={`Form-phone-input`}
            value={props.values.phoneNumber}
            inputProps={{name: 'phoneNumber'}}
            onChange={(values: any, country: any, event: any) => {
              event.persist();
              if (event.target) {
                props.values['country'] = country;
                props.handleChange(event);
              }
            }}
          />

          {props.errors.phoneNumber && (
            <span className={'input-error-phone Form-input-error'}>{<FormattedMessage
              id={props.errors.phoneNumber}/>}</span>
          )}
        </div>
      </Form.Item>

      <Form.Item>
        <Input
          name={'email'}
          maxLength={50}
          value={props.values.email}
          onChange={(event: any) => props.handleChange(event)}
          placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_EMAIL'})}
          style={{color: `${stateContext.currentMerchant.styles?.color1}`}}
          className={`shipment-form-input ${props.errors.email && 'shipment-form-input__error'}`}
        />

        {props.errors.email && (
          <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.email}/>}</span>
        )}
      </Form.Item>

    </form>
  );
}