import {Tooltip} from 'antd';
import {useIntl} from 'react-intl';
import {Context} from '../../../Store';
import '../AMPMFront/NewProductCard.css';
import {TagsFilled} from '@ant-design/icons';
import {MaxProductModal} from './MaxProductModal';
import {Utils} from '../../../../services/Utils.class';
import React, {useContext, useState, useEffect} from 'react';
import {ProductInterface} from '../../../product/ProductInterface';
import {MaxPurchaseProduct} from '../../actions/MaxPurchaseProduct';

export function MaxProductCard (props: {
  item: any,
  featured?: boolean,
  isClicking?: boolean,
  product: ProductInterface,
  bestSellers?: number
}) {
  const intl = useIntl();
  const merchant = Utils.getMerchantByUrl();
  const [isShow, setShow] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [promotion, setPromotion] = useState({});
  const [hideProductCode, setHideProductCode] = useState(false);
  const [hideAvailableStock, setHideAvailableStock] = useState(false);
  const [hideProductDescription, setHideProductDescription] = useState(false);

  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('product');

  useEffect(() => {
    getRules();
    if (id === props.product.id) {
      setShow(true);
    }
  }, []);

  const getNoIvaPrice = (product: any) => {
    return (parseFloat(product.models[0].price) / (1 + (product.models[0].details.find((e: any) => e.type === 'impuesto').value / 100)));
  };

  const getRules = () => {
    if (state.ruleParams.hideProductCode) setHideProductCode(true);
    if (state.ruleParams.hideAvailableStock) setHideAvailableStock(true);
    if (state.ruleParams.hideProductDescription) setHideProductDescription(true);
  };

  let img = (
    <i
      id={`Cashier-item-img-${props.product.name}`}
      className={'large material-icons Customer-merchant-icon'}
    >loyalty</i>
  );

  const getTotalPriceProduct = (product: any) => {
    let total = product.price * (cart[product.id] ? cart[product.id] : 1);
    if (promotion) {
      const {type, value, percent} = promotion;
      if (type === 'regular' && !product.promoPrice && cart[product.id] >= 1) {
        let productPriceDiscount = ((product.price / 100) * percent);
        total = (product.price - productPriceDiscount) * cart[product.id];
      }
      if (type === 'quantity' && !product.promoPrice && cart[product.id] >= Number(value)) total -= (product.price / 100) * percent;
    }
    return total;
  };

  if (props.item.models.filter(e => e.imgId !== '').length) {
    img = (<div className={'Featured-Product-item-Img-cont'}>
        <img
          alt={'logo'}
          id={`${props.product.imgId}`}
          className={'Featured-Product-item-Img'}
          src={`https://ciisa-images.s3.amazonaws.com/PRODUCTS/${props.product.code}.png`}
        /></div>
    );
  }

  let price = (
    <p id={`Cashier-item-price-${props.product.id}`} className={promotion.percent || props.product.promoPrice ? 'Max-product-item-price' : 'Max-product-item-price-withOutPromo'}>
      {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getNoIvaPrice(props.item), state.ruleParams.urlStockEnable ? 0 : 2)}
    </p>
  );

  let promoPrice = (<></>);
  if (promotion.percent) {
    price = (

      <p id={`Cashier-item-price-${props.product.id}`} className={'New-item-price-Promo'}>
        {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getTotalPriceProduct(props.product), state.ruleParams.urlStockEnable ? 0 : 2)}
      </p>

    );
    promoPrice = <p id={`Cashier-item-price-${props.product.id}`} className={'New-product-item-price-withOutPromo'}>

      {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney((cart[props.product.id] ? cart[props.product.id] : 1) * props.product.price - (props.product.price * (promotion.percent / 100)), state.ruleParams.urlStockEnable ? 0 : 2)}
    </p>;
  }

  if (props.product?.promoPrice > 0) {
    price = <p id={`Cashier-item-price-${props.product.id}`} className={'New-item-price-Promo'}>
      {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(props.product.promoPrice, state.ruleParams.urlStockEnable ? 0 : 2)}
    </p>;
    promoPrice = (
      props.product.promoPrice && props.product.promoPrice > 0 ? (
          <p id={`Cashier-item-price-${props.product.id}`} className={'New-product-item-price-withOutPromo'}>
            {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney((cart[props.product.id] ? cart[props.product.id] : 1) * props.product.price, state.ruleParams.urlStockEnable ? 0 : 2)}
          </p>
        )
        : <p/>
    );
  }

  let mark;
  let measure;
  props.product.details?.map((currentDetail: any) => {
    if (currentDetail.type === 'medida') measure = currentDetail.value;

    if (currentDetail.type === 'marca') mark = currentDetail.value;
  });

  const showModal = () => {
    isShow ? setShow(false) : setShow(true);
  };

  return (
    <div className={props.featured ? 'Max-product-main-container-Feat' : 'Max-product-main-container'}>
      <div>
        {isShow ? <MaxProductModal item={props.item} key={props.product.id} visible={isShow} setShowModal={setShow} product={props.product} products={state.products[merchant]}/> : ''}
      </div>
      <div className={'New-product-item-img'} onClick={() => {
        showModal();
      }}>
        {
          props.bestSellers?<div className={"bestSellersTop"}>#{props.bestSellers}</div>
            : <></>
        }
        {img}
      </div>

      <div id={`Cashier-item-name-${props.product.id}`} className={'New-product-item-name'}>{props.product.name}</div>

      {
        props.item.models[0].details.filter((e: any) => e.type === 'singleSelection').length || props.item.models[0].details.filter((e: any) => e.type === 'buyTogether').length ?
          <div id={`Cashier-item-combo-${props.product.id}`} className={'Cashier-item-combo'}>Combo</div> :
          <></>
      }

      {promotion.percent  || props.product.promoPrice && props.product.promoPrice > 0 ?
        <div className={'New-Icon-Outlined-Gift-card'}>
          <Tooltip title={props.product.promo.description}>
            <TagsFilled style={{fontSize: '20px'}}/>
          </Tooltip>
          {state.ruleParams?.promotionIconText &&
            <div className={'New-Icon-Promotion-Text'}>{promotion.percent ? `${Math.floor(promotion.percent)}%` : `${Math.floor(((props.product.promoPrice - props.product.price) / props.product.promoPrice) * 100)}%`}</div>
          }
        </div> : <></>
      }
      <div className={'New-item-controls-content'}>
        <div className={'New-item-price-Container'}>
          {price}
          {promoPrice}
        </div>

        <div className={'Max-item-controls'}>
          {
            props.product.details.filter((e: any) => e.type === 'singleSelection').length && !cart[props.product.id] ?
              <div className={(!props.product.quantity || props.product.price == 0) ? 'PurchaseProduct__unavailable' : `Max-product-Purchase`}
                   onClick={showModal}>
                <div className={!props.product.quantity ? 'Max-item-withOutStock' : 'Max-item-withStock'}>{intl.formatMessage({id: 'CASHIER_ACTIONS__BUTTON_BUY'})}</div>
              </div>
              :
              <MaxPurchaseProduct product={props.product} hideAvailableStock={false}/>
          }
        </div>
      </div>
    </div>
  );
}
