import {PickupInterface} from './PickupInterface';

export function PickupValidators(values:PickupInterface):PickupInterface {
  let errors:PickupInterface = {};

  if (!values?.pickup?.code) {
    errors.code = 'CASHIER_PICKUP__VALIDATION_STORE';
  }

  return errors;
}