import {Modal} from 'antd';
import {useIntl} from 'react-intl';
import customMap from './CustomMap';
import GoogleMapReact from 'google-map-react';
import * as HttpStatus from 'http-status-codes';
import {Utils} from '../../services/Utils.class';
import React, {useEffect, useState} from 'react';
import img from '../../static/markerIcon/markerIcon.json';
import {HttpRequest} from '../../services/HttpRequest.class';

export function Map(props: {
    state?:any,
    marker?:any,
    search?:any,
    zoom?:number,
    valueLocation?:any,
    setAddress:Function,
    getStoreId:Function,
    getPolygons:Function,
    handleChange:Function,
    setUserInRange:Function,
}) {

    let markers:any = [];
    let polygons:any = [];
    const intl = useIntl();
    const [visible, setVisible] = useState(false);
    const [userLocation, setUserLocation] = useState({
        lat: Number(props.state?.location?.lat||9.9356284),
        lng: Number(props.state?.location?.long||-84.1483648)
    });
    const [lastLocation, setLastLocation] = useState(userLocation);
    const [mapApi, setMap] = useState({map: '', maps: ''});


    useEffect(()=>{
        if (props.valueLocation) {
            setUserLocation({lat: props.valueLocation.lat, lng: props.valueLocation.lng});
        }
    }, [])

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
    }

    function showPosition(position:any) {
        setUserLocation({lat: position.coords.latitude, lng: position.coords.longitude});

        props.handleChange({
            target: {
                name: 'location',
                value: userLocation
            }
        });
    }

    const apiGoogleOnLoad = async (map:any, maps:any) => {
        mapApi.map = map;
        mapApi.maps = maps;
        await drawPolygons(map, maps);
        await initAutocomplete(map, maps);
    }

    const initAutocomplete = async (map:any, maps:any) => {
        if (!props.search.current) return;

        const option = {
            setFields: ['address_component', 'geometry'],
            componentRestrictions: {country: props.state.country.countryCode},
            types: ['address'],
        }

        let marker = await userMarker(maps, userLocation);
        marker.setMap(map);

        const autocomplete = new window.google.maps.places.Autocomplete(props.search.current, option);
        autocomplete.addListener('place_changed', () => {
            onChangeAddress(autocomplete);
            marker.setPosition(userLocation);
            map.setCenter(userLocation);
            marker.getDraggable();
            map.setZoom(15);
            }
        );
        getLocationByMarker(maps, marker);
    }

    const getCoordsByCountry = async () => {
        const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
        const requestResponse = await request.get(`shipment/coordinates/${props.state.country.countryCode}`, {}, {'Accept': 'application/json'});

        if (requestResponse.status === HttpStatus.OK) {
            return requestResponse.data;
        }

        return null;
    }

    const drawPolygons = async (map:any, maps:any) => {
        const dataStores = props.state.id == 'ampm' ? await props.getPolygons() : await getCoordsByCountry();
        return dataStores && dataStores.map((store:any) => {
            let polygonArea = new maps.Polygon({
                paths: store,
                strokeWeight: 1,
                fillOpacity: 0.15,
                strokeOpacity: 0.3,
                fillColor: "#1890ff",
                strokeColor: "#0883ff",
            });

            polygons.push(polygonArea);
            polygonArea.setMap(map);
        });
    }

    const extractAddress = (place:any) => {

        const address = {
            city: '',
            state: '',
            country: '',
            district: '',
            postalCode: '',
        }

        if (!Array.isArray(place?.address_components)) return address;

        props.setAddress(place.formatted_address);

        place.address_components.forEach((component:any) => {
            const types = component.types;
            const value = component.long_name;

            if (types.includes('locality')) address.district = value;

            if (types.includes('country')) address.country = value;
            
            if (types.includes('administrative_area_level_1')) address.state = value;
    
            if (types.includes('postal_code')) {address.postalCode = value;} else address.postalCode = '10101';
    
            if (types.includes('administrative_area_level_2')) {address.city = value;}  else address.city = 'San josé';
        });

        props.handleChange({
            target: {
                name: 'city',
                value: address.city
            }
        });

        props.handleChange({
            target: {
                name: 'state',
                value: address.state
            }
        });

        props.handleChange({
            target: {
                name: 'country',
                value: address.country
            }
        });

        props.handleChange({
            target: {
                name: 'district',
                value: address.district
            }
        });

        props.handleChange({
            target: {
                name: 'postalCode',
                value: address.postalCode
            }
        });
    }

    const onChangeAddress = (autocomplete:any) => {
        const place = autocomplete.getPlace();

        calculateDistance({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});

        userLocation.lat = place.geometry.location.lat();
        userLocation.lng = place.geometry.location.lng();

        extractAddress(place);
        props.handleChange({
            target: {
                name: 'location',
                value: userLocation
            }
        });

        checkUserIsInArea(mapApi.maps, polygons, userLocation);
        props.getStoreId(userLocation);
    }

    const userMarker = async (maps:any, locationUser:any) => {
        return new maps.Marker({
            draggable:true,
            position: locationUser,
            animation: maps.Animation.DROP,
            icon:`data:image/png;base64,${img.marker}`,
        });
    };

    const getLocationByMarker = (maps:any, marker:any) => {
        maps.event.addListener(marker, 'dragend', () => {
            calculateDistance({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});

            userLocation.lat = marker.getPosition().lat();
            userLocation.lng = marker.getPosition().lng();
            checkUserIsInArea(maps, polygons, userLocation);
            reverseGeoCode(userLocation);
            props.getStoreId(userLocation);

            props.handleChange({
                target: {
                    name: 'location',
                    value: userLocation
                }
            });
        });
    }

    const checkUserIsInArea = async (maps:any, areas:any, pathCoordinates:any) => {
        let isInRange = areas.filter((area:any) => {
            return maps.geometry.poly.containsLocation(pathCoordinates, area);
        });
        if (!isInRange.length) Utils.showNotificationMessage('info', intl.formatMessage({id:'SHIPMENT__FORM_ERROR_OUT-OF-RANGE'}), intl.formatMessage({id:'SHIPMENT__FORM_ERROR_PICK-A-COVERAGE'}));
        props.setUserInRange(true);
    }

    const reverseGeoCode = async (location:any) => {
        const request = new HttpRequest('https://maps.googleapis.com'|| '');
        const requestResponse = await request.get(`maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyA8FcoNMaYnK7jzTxxdGWvN8YovLjwIG1k`, {}, {'Accept': 'application/json'});

        if (requestResponse.status === HttpStatus.OK) {
            return extractAddress(requestResponse.data.results[0])
        }
    }

    const calculateDistance = (newPosition:any) => {
        const pointCustomerA = new window.google.maps.LatLng(userLocation);
        const pointCustomerB = new window.google.maps.LatLng(newPosition);

        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(pointCustomerA, pointCustomerB);

        if (distance > 1000) {
          //  if (!visible) setVisible(true);
        }
    }

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
        //setUserLocation(lastLocation);
       //reverseGeoCode(userLocation);
       // marker.setPosition(userLocation);
    };

  return (
      <>
          <div>
              <Modal
                onOk={handleOk}
                visible={visible}
                onCancel={handleCancel}
              >
                  <p>¿Esta dirección se encuentra lejos, deseas seleccionar igualmente?</p>
              </Modal>
          </div>
          <GoogleMapReact
              defaultZoom={9}
              zoom={props.zoom}
              center={userLocation}
              options={{styles: customMap.customMap}}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({map, maps}) => apiGoogleOnLoad(map, maps)}
              bootstrapURLKeys={{key:'AIzaSyA8FcoNMaYnK7jzTxxdGWvN8YovLjwIG1k',
                  v: '3.30',
                libraries: ['places', 'geometry']
              }}
          >
          </GoogleMapReact>
      </>
  )
}