import './PurchaseProduct.css';
import {useIntl} from 'react-intl';
import {Context} from '../../Store';
import React, {useContext} from 'react';
import {AddAndRemoveProduct} from './AddAndRemoveProduct';
import {Utils} from '../../../services/Utils.class';

export function PurchaseProduct(props: {
  product: any,
  merchant: string,
  editing?: boolean,
  selectingCombo?: any,
  hideAvailableStock: boolean
}) {
  const intl = useIntl();
  const [state, dispatch] = useContext(Context);
  const cart = state.merchantParams?.noClient ? (state.cart[ state.merchantParams.noClient ] ? state.cart[ state.merchantParams.noClient ] : state.cart[ 0 ]) : state.cart[ 0 ];
  const merchant = Utils.getMerchantByUrl();

  if (!cart[ props.product?.id ]) {
    return (
      <div style={{
        fontFamily: state.currentMerchant.styles.font,
        backgroundColor: `${props.product?.quantity && props.product?.price != 0 ? state.currentMerchant.styles.color2 : 'silver'}`,
        width: '20rem'
      }} className={props.editing ?
        ((!props.product?.quantity || props.product?.price == 0 || props.selectingCombo) ? `New-product-Purchase-unn` : `New-product-Purchase`) :   props.product?.quantity  ?'PurchaseProduct' : 'PurchaseProduct__unavailable'}
           onClick={props.product?.quantity != 0 && props.product?.price != 0 && props.product?.id ? () => dispatch({
             type: 'ADD-CARD',
             payload: {
               [ props.product.id ]: 1
             }
           }) : () => false}>
        <span
          className={'Cashier-item-withOutStock'}>{props.product?.quantity && props.product?.price != 0 ? intl.formatMessage({id: 'CASHIER_ACTIONS__BUTTON_BUY'}) : !props.product ? intl.formatMessage({id: 'MERCHANT_COMBO_REQUIRED'}) : intl.formatMessage({id: 'CASHIER_ACTIONS__BUTTON_SOLDOUT'})}</span>
      </div>
    );
  }

  const quantity = props.product ? (props.product.quantity - (cart[ props.product.id ] ? cart[ props.product.id ] : 0)) : 0;
  let message = !props.hideAvailableStock
    ? merchant === 'pumacolombia' ? Number(cart[ props.product.id ]) > 1 ? Number(cart[ props.product.id ]) + ' ' + intl.formatMessage({id: 'UNITS'}) : Number(cart[ props.product.id ]) + ' ' + intl.formatMessage({id: 'UNIT'}) : quantity + (quantity > 0 ? '+' : '') + (quantity == 1 ? intl.formatMessage({id: 'CASHIER_ACTIONS__LABEL_AVAILABLE'}) : intl.formatMessage({id: 'CASHIER_ACTIONS__LABEL_AVAILABLE_PLURAL'})) : '';

  return <AddAndRemoveProduct product={props.product} message={message}/>;
}