import './Search.css';
import {Input} from 'antd';
import './NewSearchBar.css';
import {useIntl} from 'react-intl';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import React, {useState, useRef, useEffect} from 'react';

const {Search} = Input;

export function NewSearchBar(props:  {
  showSearch: any,
  setShowSearch:Function,
  setSearchContent:Function,
  merchant: any,
  searchValue: string,
  setSearchValue: Function,
  setProducts: Function,
  setLoadingContent: Function,
}) {
  const isMobile = window.innerWidth < 950
  return (
      <div className={isMobile ? 'NewSearchContainer' : 'NewSearchContainerDesktop'}>
        <input autoFocus={true} onChange={(e)=>props.setSearchValue(e.target.value)} type={'text'} placeholder={'Buscar'} className={'NewSearchInput'}/>
        <CloseOutlined onClick={() => {
          props.setShowSearch(!props.showSearch)
          props.setSearchContent(false)
        }} className={'NewSearchIcon'}
        />
      </div>
  );
}