import React, {useEffect, useRef, useState} from 'react';

export function useScrollHandler(contentRef: any) {
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isClicking, setIsClicking] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
      setIsClicking(true);
      setStartX(e.pageX - content.offsetLeft);
      setScrollLeft(content.scrollLeft);
    };

    const handleMouseUp = () => {
      if (!isScrolling && !isDragging) {
        setIsClicking(false);
      }
      if (!isScrolling) {
        setIsDragging(false);
        setIsClicking(false);
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - content.offsetLeft;
      const walk = (x - startX);
      content.scrollLeft = scrollLeft - walk;
    };

    const handleMouseLeave = () => {
      setIsDragging(false);
      setIsScrolling(false);
    };

    const handleScroll = () => {
      setIsScrolling(true);
    };

    content.addEventListener('mousedown', handleMouseDown);
    content.addEventListener('mouseup', handleMouseUp);
    content.addEventListener('mousemove', handleMouseMove);
    content.addEventListener('mouseleave', handleMouseLeave);
    content.addEventListener('scroll', handleScroll);

    return () => {
      content.removeEventListener('mousedown', handleMouseDown);
      content.removeEventListener('mouseup', handleMouseUp);
      content.removeEventListener('mousemove', handleMouseMove);
      content.removeEventListener('mouseleave', handleMouseLeave);
      content.removeEventListener('scroll', handleScroll);
    };
  }, [isDragging, startX, scrollLeft, isScrolling]);

  useEffect(() => {
    const handleMouseUpOutside = () => {
      setIsDragging(false);
      setIsScrolling(false);
    };

    window.addEventListener('mouseup', handleMouseUpOutside);

    return () => {
      window.removeEventListener('mouseup', handleMouseUpOutside);
    };
  }, []);

  return [contentRef, isClicking];
}

export function ScrollHandlerWrapper(props:{
  viewChange:boolean
  children:any
}) {
  const [contentRef, isClicking] = useScrollHandler(useRef<HTMLDivElement>(null));

  return props.viewChange ? props.children(contentRef, isClicking) : null;
}