import {FACInterface} from './FACInterface';

export function FACValidators(values:FACInterface):FACInterface {
  let errors:FACInterface = {};

  if (!values.name) {
    errors.name = 'El nombre es requerido';
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'El correo electrónico es incorrecto';
  }

  if (!values.DNI) {
    errors.DNI = 'El DNI es requerido';
  }

  let phone = values.phoneNumber || '';
  phone = phone.replace(/\s/g, '');
  phone = phone.replace(/-/g, '');

  if (!values.phoneNumber) {
    errors.phoneNumber = 'El teléfono es requerido';
  } else if (!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phone)){
    errors.phoneNumber = 'El teléfono es incorrecto';
  }

  if (!values.country) {
    errors.country = 'El país es requerido';
  }

  if (!values.state) {
    errors.state = 'El estado es requerido';
  }

  if (!values.city) {
    errors.city = 'La ciudad es requerida';
  }

  if (!values.street) {
    errors.street = 'La calle es requerida';
  }

  return errors;
}
