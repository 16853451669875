import React from 'react';
import {Skeleton} from 'antd';
import './SkeletonMiscellaneuos.css';

export function SkeletonMiscellaneuos() {
  return (
    <div className={'skeleton-miscellaneuos'}>
      <div className={'skeleton-form'}>
        <Skeleton.Input size={'large'} active={true} className={'skeleton-inputs skeletonInputResume'}/>
        <Skeleton.Input size={'large'} active={true} className={'skeleton-inputs skeletonInputOrders'}/>
        <Skeleton.Input size={'large'} active={true} className={'skeleton-inputs skeletonInputTotal'}/>
        <Skeleton.Button size={'large'} active={true} className={'skeleton-inputs skeletonButtonConfirm'}/>
        <Skeleton.Button size={'large'} active={true} className={'skeleton-inputs skeletonButtons'}/>
      </div>
    </div>
  )
}