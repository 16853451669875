import React from "react";
import "./SkeletonPayU.css";
import {Skeleton} from 'antd';

export function SkeletonPayU() {
  return (
    <div className={'skeletonPayU'}>
      <Skeleton.Input size={"small"} active={true} className={'skeletonPrice'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInputSteps'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInput'}/>
      <Skeleton.Button size={"large"} active={true} className={'skeletonButton'}/>
    </div>
  )
}