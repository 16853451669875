import './EditingOrder.css';
import {Context} from '../../../Store';
import {NewCart} from '../../cart/NewCart';
import {useRouteMatch} from 'react-router';
import {useLocation} from 'react-router-dom';
import {BarsStep} from '../../../spinner/BarsStep';
import * as HttpStatus from 'http-status-codes';
import {ProductItem} from '../ProductItem';
import {LoadingOutlined} from '@ant-design/icons';
import {Utils} from '../../../../services/Utils.class';
import {NewSearchBar} from '../../search/NewSearchBar';
import {FormattedMessage, useIntl} from 'react-intl';
import {NewCategoryCard} from './NewCategoryCard';
import {PromotionScreen} from '../../promotions/PromotionScreen';
import {HttpRequest} from '../../../../services/HttpRequest.class';
import {SearchOutlined, CloseOutlined} from '@ant-design/icons';
import {Breadcrumb, Divider, Empty, Input, Spin, Alert} from 'antd';
import React, {Suspense, useContext, useEffect, useRef, useState} from 'react';
import {ProductCategoryInterface} from '../../../productCategory/ProductCategoryInterface';
import {AMPMFilter} from './AMPMFilter';

export function EditingOrder (props: {
  products:any,
  storeId:number,
  current: number,
  ShowAddress:any,
  pickupValues:any,
  addressValues:any,
  getOrder: Function,
  selectedPickup: any,
  setIdStore: Function,
  setCurrent: Function,
  SubmitPickup: Function,
  SubmitAddress: Function,
  discountPercent: number,
  setStepEnabled: Function,
  setDiscountPercent: Function,
  setPriceWithDiscount:Function,
}) {
  const intl = useIntl();
  const productsI:any[] = [];
  const categoriesI:any[] = [];
  const paymentMethodsI:any[] = [];
  const actualLocation = useLocation();
  const merchant = Utils.getMerchantByUrl();
  const [alerts, setAlerts] = useState(<></>);
  const [content, setContent] = useState(<></>);
  const [state, dispatch] = useContext(Context);
  const [order, setOrder] = useState([]);
  const [promos, setPromos] = useState<any[]>([]);
  const [products, setProducts] = useState(productsI);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState(categoriesI);
  const [allCategories, setAllCategories] = useState(categoriesI);
  const [filtering, setFiltering] = useState(false);
  const [categoriesFetched, setCategoriesFetched] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [validStock, setValidStock] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const [lastCategory, setLastCategory] = useState('');
  const [loadProducts, setLoadProducts] = useState(false);
  const [searchContent, setSearchContent] = useState(false);
  const [priceWithPoints, setPriceWithPoints] = useState(0);
  const [filterContent, setFilterContent] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loyaltyClientId, setLoyaltyClientId] = useState('');
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadCategories, setLoadCategories] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(paymentMethodsI);
  const [searchCategories, setSearchCategories] = useState([]);
  const [loyaltyPointsPath, setLoyaltyPointsPath] = useState('');
  const [promotionVisible, setPromotionVisible] = useState(false);
  const [nonFilteredCategories, setNonFilteredCategories] = useState(categoriesI);
  const [productsWithoutCategory, setProductsWithoutCategory] = useState([]);
  const [promotionScreen, setPromotionScreen] = useState(true);
  const [sessionProducts, setSessionProducts] = useState<any>({});
  const [filters, setFilters] = useState([]);
  const [loadingPromo, setLoadingPromo] = useState<any>(false);
  const [logo, setLogo] = useState(<i
    className={'medium material-icons Cashier-header-content-logo'}>store</i>);
  const orderId = useRouteMatch().url.split('/')[1];
  const [virtualPath, setVirtualPath] = useState('');

  const searchInput = useRef<Input>(null);
  const [refInputSearch, setRefInputSearch] = useState(searchInput);

  let idProductShipping = '';
  let idVariableProduct = '';

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');

  const [loadingCart, setLoadingCart] = useState(true);
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  useEffect(() => {
    dispatch({
      payload: merchant,
      type: 'ID-MERCHANT'
    });
    setLoading(true)
    setLoadingContent(true);


    getRules()
    getFilters()
    getLoyaltyPoints().then();
    getPaymentMethods().then();
    cartBackup();

    document.title = state.currentMerchant.id.toUpperCase();
    const favicon:any = document.getElementById('favicon');

    if (favicon) {
      favicon.href = `${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${state.currentMerchant.logoId}`;
    }
  }, []);

  useEffect(() => {
    setLoadingContent(true)
    setLoadCategories(true);
    dispatch({
      payload: merchant,
      type: 'ID-MERCHANT'
    });
    setLogo(<img
      alt={'logo'}
      className={'Editing-logo'}
      onClick={()=>{
        setProducts([]);
        setCategory('');
        setVirtualPath('');
        setBreadcrumbs([]);
        setLastCategory('');
      }
      }
      src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${state.currentMerchant.logoId}`}
    />);
  }, []);

  useEffect(()=>{
    state.products[merchant] = []
    if(props.storeId) {
      setLoadingPromo(true)
      getDataPromotions().then(()=>{
        setLoading(false)
        setLoadingPromo(false)
      });
      if(!categories.length && !allCategories.length && !categoriesFetched){
        setCategoriesFetched(true)
        getProductCategories().then(() => {
          setLoadCategories(false);
          setLoadingContent(false);
        });
      }
    }
  },[props.storeId])

  useEffect(() => {
    window.scrollTo(0, 0);

    if (filterContent && !loadingContent) {
      const prods = products.length ?
        <div className={'Cashier-item-list-editing'}>
          {products.filter(e=> e.models[0].quantity>0).map((current:any, index: number) =>
            <ProductItem merchant={merchant}
              editing
              key={index}
              item={current}
              products={products}
            />)

          }{
          products.filter(e => e.models[ 0 ].quantity == 0).map((current: any, index: number) =>
            <ProductItem merchant={merchant}
              editing
              key={index}
              item={current}
              products={products}
            />)}
        </div>
        : <></>

      if(!products.length){
        setContent(<Empty
          description={<span><FormattedMessage
            id={'CASHIER_STOCK_CASHIER__LABEL_EMPTY_PRODUCTS'}/></span>}
          style={{width: '100%', marginTop: '100px', textAlign: 'center'}}
        />)
      }
      else(   setContent(
        <div className={'editingSearchContent'}>
          {prods}
        </div>
      ))

    }
    else if (showPromo) {
      const prods = products.length ?
        <div className={'Cashier-item-list-editing'}>
          {
            products.filter(e=> e.models[0].quantity>0).map((current:any, index: number) => {
              let price = Utils.formatMoney(current.price);
              let percent = Utils.formatMoney(current.promotions.sort(function (a:any, b:any) {
                return b.percent - a.percent;
              })[0].percent);
              let promoPrice = price - (price * (percent / 100));
              return (
                <ProductItem merchant={merchant}
                  editing
                  key={index}
                  item={{...current, promoPrice: promoPrice}}
                  products={products}
                />);})
            }
          {products.filter(e=> e.models[0].quantity==0).map((current:any, index: number) => {
              let price = Utils.formatMoney(current.price);
              let percent = Utils.formatMoney(current.promotions.sort(function (a:any, b:any) {
                return b.percent - a.percent;
              })[0].percent);
              let promoPrice = price - (price * (percent / 100));
              return (
                <ProductItem merchant={merchant}
                  editing
                  key={index}
                  item={{...current, promoPrice: promoPrice}}
                  products={products}
                />);
            })}

        </div>
        : <></>;

      const body = <div className={'editing-item-list-editing-ampm'}>
        {
          products.length ?
            prods
            :
            <Empty
              description={<span><FormattedMessage
                id={'CASHIER_STOCK_CASHIER__LABEL_EMPTY_PRODUCTS'}/></span>}
              style={{width: '100%', marginTop: '100px', textAlign: 'center'}}
            />

        }
      </div>;

      setContent(body);
    }
    else if (searchContent && !loadingContent) {
      const prods = products.length ?
        <div className={'Cashier-item-list-editing'}>
          {products.filter(e=> e.models[0].quantity>0).map((current:any, index: number) =>
            <ProductItem merchant={merchant}
              editing
              key={index}
              item={current}
              products={products}
            />)}
          {products.filter(e=> e.models[0].quantity==0).map((current:any, index: number) =>
            <ProductItem merchant={merchant}
              editing
              key={index}
              item={current}
              products={products}
            />)}
        </div>
        : <></>

      if(!products.length){
        setContent(<Empty
          description={<span><FormattedMessage
            id={'CASHIER_STOCK_CASHIER__LABEL_EMPTY_PRODUCTS'}/></span>}
          style={{width: '100%', marginTop: '100px', textAlign: 'center'}}
        />)
      }
      else(   setContent(
        <div className={'editingSearchContent'}>
          {prods}
        </div>
      ))

    }
    else if ( (categories.length || products.length) && !searchContent) {
      clearSearch();
      const prods = products.length ?
        <div className={'Cashier-item-list-editing'}>
          {categories.length ? <Divider/> : <></>}
          {products.filter(e=> e.models[0].quantity>0 && e.models[0].id!=="nhlv2g").map((current:any, index: number) =>
            <ProductItem merchant={merchant}
              editing
              key={index}
              item={current}
              products={products}
            />)}
          {products.filter(e=> e.models[0].quantity== 0 && e.models[0].id!=="nhlv2g").map((current:any, index: number) =>
            <ProductItem merchant={merchant}
              editing
              key={index}
              item={current}
              products={products}
            />)}
        </div>
        : <></>;
        let tempCat = []
      const body = <div className={'editing-item-list-editing-ampm'}>
        {
          !virtualPath?.length || virtualPath.split('/')?.length < 2 && !products.length ?

            <>
              {
                (category && filtering) ?
                  categories.map(current => {
                    if (current.hasOwnProperty('parent') && current.parent === category && !tempCat.find(e=>e===current.id)) {
                      tempCat.push(current.id)
                      return <NewCategoryCard
                        setSearchContent={setSearchContent}
                        setVirtualPath={setVirtualPath}
                        key={current.id}
                        editing={true}
                        setCategory={setCategory}
                        setLastCategory={setLastCategory}
                        setProducts={setProducts}
                        productCategory={current}
                        lastCategory={lastCategory}
                        category={category}
                        setLoadProducts={setLoadProducts}
                        setLoadingContent={setLoadingContent}
                        setLoadCategories={setLoadCategories}
                      />;
                    }
                  }) :
                  categories.map(current => {
                    if (!category && !tempCat.find(e=>e===current.id)) {
                      tempCat.push(current.id)
                      return <NewCategoryCard
                        setSearchContent={setSearchContent}
                        setVirtualPath={setVirtualPath}
                        key={current.id}
                        editing={true}
                        setCategory={setCategory}
                        setLastCategory={setLastCategory}
                        productCategory={current}
                        setProducts={setProducts}
                        lastCategory={lastCategory}
                        category={category}
                        setLoadProducts={setLoadProducts}
                        setLoadingContent={setLoadingContent}
                        setLoadCategories={setLoadCategories}
                      />;
                    }

                    if (category && current.name === category && current.hasOwnProperty('children') && !tempCat.find(e=>e===current.id)) {
                      tempCat.push(current.id)
                      return current.children.map((currentChild: ProductCategoryInterface) => {
                        if(currentChild.data.length) {
                          return <NewCategoryCard
                            setSearchContent={setSearchContent}
                            setVirtualPath={setVirtualPath}
                            key={currentChild.id}
                            editing={true}
                            setCategory={setCategory}
                            setLastCategory={setLastCategory}
                            setProducts={setProducts}
                            lastCategory={lastCategory}
                            category={category}
                            productCategory={currentChild}
                            setLoadProducts={setLoadProducts}
                            setLoadingContent={setLoadingContent}
                            setLoadCategories={setLoadCategories}
                          />;
                        }
                      });
                    }
                  })
              }
              <div className={'AMPMfoot'}/>
            </> :
            products.length ?
              prods
              :
              <Empty
                description={<span><FormattedMessage
                  id={'CASHIER_STOCK_CASHIER__LABEL_EMPTY_PRODUCTS'}/></span>}
                style={{width: '100%', marginTop: '100px', textAlign: 'center'}}
              />
        }
      </div>;

      setContent(body);
    }
  }, [categories, virtualPath, products, showPromo, searchContent, filterContent, order]);

  useEffect(() => {
    if (category && categories.length) {
      clearSearch();
      let description = '';
      let nonCategories = false;
      categories.map(current => {
        if (current.name.toLowerCase().includes(category)) {
          description = current.description;
        }

        if (current.hasOwnProperty('children') && (current.children.length === 0) || current.type === 2 || !current.hasOwnProperty('children')) {
          nonCategories = true;
        }

        if (!nonCategories && !loadProducts) setProducts([]);
      });

      if (description === '') {
        state.categories[merchant].map((foundedCategory:any) => {
          if (foundedCategory.name.toLowerCase().includes(category)) {
            description = foundedCategory.description;
          }

          if (foundedCategory.hasOwnProperty('children') && (foundedCategory.children.length === 0) || foundedCategory.type === 2 || !foundedCategory.hasOwnProperty('children')) {
            nonCategories = true;
          }

          if (!nonCategories && !loadProducts) setProducts([]);
        });
      }

      let breadcrumbCategory:any = [];
      breadcrumbCategory.push(
        <Breadcrumb.Item
          key={`${category}`}
          onClick={() => {
            setLoadingContent(false);
            setVirtualPath(virtualPath.split('/')[1]);
            setCategory(category);
            setLastCategory(category);

          }}
        >

        <span style={{
          fontFamily: state.currentMerchant.styles.font,
          color: `${state.currentMerchant.styles?.color1}`
        }} className={'Cashier-breadcrumb-span'}>{description.length>17?description.slice(0,16)+'...':description}</span>

        </Breadcrumb.Item>
      );

      if (lastCategory !== category) {
        let subDescription = '';
        state.categories[merchant].map(function f (current:any) {
          if (current.hasOwnProperty('parent') && current.name.toLowerCase().includes(lastCategory)) {
            nonCategories = true;
            subDescription = current.description;
          }

          if (current.hasOwnProperty('children')) {
            return (current.children = current.children.filter(f)).length;
          }
        });

        breadcrumbCategory.push(
          <Breadcrumb.Item key={category}>
            <span style={{
              fontFamily: state.currentMerchant.styles.font,
              color: `${state.currentMerchant.styles?.color1}`
            }} className={'Cashier-breadcrumb-span'}>{lastCategory}</span>
          </Breadcrumb.Item>
        );
      }

      if (breadcrumbCategory.length && !products.length && nonCategories && !filtering) {
        setLoadProducts(true);
      }

      setBreadcrumbs(breadcrumbCategory);
    }
  }, [virtualPath]);

  useEffect(() => {
    if (state.merchantParams?.identification) getLoyaltyPoints().then();
  }, [state.merchantParams?.identification]);

  useEffect(() => {
    if (priceWithPoints > 0) {
      props.setPriceWithDiscount(priceWithPoints);
    }
  }, [priceWithPoints]);

  useEffect(() => {
    const category = actualLocation.pathname.split('/')[2];
    console.log(actualLocation);
    const product = actualLocation.pathname.split('/')[3];

    if (actualLocation.pathname === `/0/${category}` || actualLocation.pathname === `/0/${category}/${product}`) {
      setPromotionVisible(false);
    }
  }, [breadcrumbs]);

  useEffect(() => {
    if (!validStock) {
      setAlerts(
        <Alert
          banner
          showIcon
          closable
          type={'warning'}
          className={'Alert-stock'}
          message={intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__MESSAGE_STOCK_UPDATE'})}
          description={intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__DESCRIPTION_STOCK_UPDATE'})}
        />);
    }
  }, [validStock]);

  useEffect(() => {
    if (category !== lastCategory) {
      const category = allCategories.filter(e=>e.name==virtualPath.split('/')[1] && e.parent ===virtualPath.split('/')[0])[0]?.id
      const temp = async () => {
        await getProductsByCategory(category);
      };
      temp();
    }
  }, [virtualPath]);

  useEffect(() => {
    if(searchValue.length===0)setSearchContent(false);
    if (searchValue.length > 3) {
      let temp = async () => {
        await SearchRequest()
      }
      temp()
    }
  }, [searchValue]);

  useEffect(()=>{
    if(!searchContent){
      setProducts([])
      setSearchCategories([])
    }
  },[searchContent])

  useEffect(() => {
    if (promotionScreen) {
      document.documentElement.classList.add('print-view');
      document.body.classList.add('print-view');
    } else {
      document.documentElement.classList.remove('print-view');
      document.body.classList.remove('print-view');
      window.scrollTo(0, 0);
    }
  }, [promotionScreen]);

  const getRules = async () => {
    const ruleRequest = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const rulesResponse = await ruleRequest.get('rule');

    let productsPath = '';
    let promotionIconText = '';
    let urlStockEnable = false;
    let quizForProducts = false;
    let hideProductCode = false;
    let hideAvailableStock = false;
    let hideScoreAndComments = false;
    let hideProductDescription = false;
    let hiddenProductCardSliders = false;
    let request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    if (rulesResponse.status === HttpStatus.OK) {
      const dataResponse = rulesResponse.data;
      dataResponse.map((current: any) => {
        if (current.name === 'getProductsPath') {
          productsPath = current.value.path;
          request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
        }

        if (current.name === 'urlStockEnable') urlStockEnable = true;
        if (current.name === 'hideProductCode') hideProductCode = true;
        if (current.name === 'quizForProducts') quizForProducts = true;
        if (current.name === 'hideAvailableStock') hideAvailableStock = true;
        if (current.name === 'hideScoreAndComments') hideScoreAndComments = true;
        if (current.name === 'hideProductDescription') hideProductDescription = true;
        if (current.name === 'productShipping') idProductShipping = current.value.id;
        if (current.name === 'variableProduct') idVariableProduct = current.value.id;
        if (current.name === 'promotionIconText') promotionIconText = current.value.text;
        if (current.name === 'hiddenProductCardSliders') hiddenProductCardSliders = true;
      });
    }

    setRuleParams({
      urlStockEnable: urlStockEnable,
      hideProductCode: hideProductCode,
      quizForProducts: quizForProducts,
      idProductShipping: idProductShipping,
      idVariableProduct: idVariableProduct,
      promotionIconText: promotionIconText,
      hideAvailableStock: hideAvailableStock,
      hideScoreAndComments: hideScoreAndComments,
      hideProductDescription: hideProductDescription,
      hiddenProductCardSliders: hiddenProductCardSliders
    });

  }

  const getFilters = async () => {
    if(!filters.length){
      const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
      const requestFilters: any = await request.get(`filters`);

      if (requestFilters.status === 200) {
        setFilters(Array.isArray(requestFilters.data) ? requestFilters.data : []);
      }
    }
  }

  const setRuleParams = (params:object) => {
    dispatch({
      type: 'ADD-RULE-PARAMS',
      payload: params
    });
  }
    let SearchRequest = async () => {
    setSearchContent(false);
    setLoadingContent(true);
    setProducts([]);
    setSearchCategories([]);

    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const requestResponse = await request.get(`ampmProduct/searchBar/${props.storeId}/${searchValue}`);

    if (requestResponse.status === HttpStatus.OK) {
      if (requestResponse.data.Products) {
        setProducts(requestResponse.data.Products.filter(e=> e.models[0].id!=="nhlv2g"));
        updateStateStock(requestResponse.data.Products.filter(e=> e.models[0].id!=="nhlv2g"))
      }
      if (requestResponse.data.Categories) {
        setSearchCategories(requestResponse.data.Categories);
      }
      if (requestResponse.data.Products || requestResponse.data.Categories) {
        setLoadingContent(false);
        setSearchContent(true);
      }
    } else {
      setProducts([]);
      setSearchCategories([]);
      setLoadingContent(false);
      setSearchContent(true);
      return null;
    }
  };

  const getDataPromotions = async () => {
    const request = new HttpRequest(`https://api.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const requestResponse = await request.get(`ampmProduct/getProductsWithPromos/${props.storeId}`);
    if (requestResponse.status === HttpStatus.OK) {
      const promos = requestResponse.data.filter(e=>
        e.models.some(f=>
        f.promo.percent !== 0));
      setPromos(promos.filter(f=>f.models[0].promo.percent>1 && f.models[0].imgId!=="" ));
      setSessionProducts({...sessionProducts, promo: promos});
      updateStateStock(promos);
    }
    return null;
  };

  const handleView = () => {
    if (changeView) setChangeView(false);
    if (!changeView) setChangeView(true);
  };

  const getProductsByPercent = async (percent: number) => {
    const filterProds = state.products[merchant];
    const filterArray = filterProds.map((model:any) => {
      for (const productModel of model.models) {
        if (productModel.promo && Object.keys(productModel.promo).length && productModel.promo.percent == percent) {
          model.models = [productModel];
          return model;
        }
      }
    }).filter((model:any) => model);
    setProducts(filterArray);
  };

  const categoryFilter = (e:any) => {
    return e.filter(function f (model:any) {
      if (model.hasOwnProperty('category') && model.hasOwnProperty('name')) {
        let query = '';
        if (category) {
          if (model.category) {
            if (model.category.includes('|')) {
              const productCategory = model.category.split('|');
              if (lastCategory !== category) {
                if (productCategory[0] === category && productCategory[1] === lastCategory) query = productCategory[1];
              } else {
                if (productCategory[0] === category)
                  query = productCategory[0];
              }

            } else {
              query = model.category;
            }
          }
          if (query == lastCategory) return true;
        } else {
          if (model.category) {
            query = model.category;
          }

          if (query == lastCategory) return true;
        }
      }

      if (model.hasOwnProperty('models')) {
        return (model.models = model.models.filter(f)).length;
      }
    });
  };

  const clearSearch = () => {
    if (refInputSearch.current?.state
      && refInputSearch.current.state.value
      && !refInputSearch.current.state.focused)
      refInputSearch.current.setValue('');
  };

  const setMerchantParams = (params: object) => {
    dispatch({
      type: 'ADD-MERCHANT-PARAMS',
      payload: params
    });
  };

  const cleanCart = () => {
    dispatch({
      type: 'CLEAN-CART'
    });
  };


  const getProductsByCategory = async (category:string) => {
    setLoadingContent(true);
    if (sessionProducts[category]) {
      setLoadingContent(false);
      setProducts(sessionProducts[category]);
    } else {
      const productCategoryRequest = new HttpRequest(`https://api.${process.env.REACT_APP_APP_DOMAIN}` || '');
      const productCategory = await productCategoryRequest.get(`ampmProduct/stock/${props.storeId}/${category}`);
      if (productCategory.status === HttpStatus.OK) {
        setLoadingContent(false);
        setProducts(productCategory.data);
        setSessionProducts({...sessionProducts, [category]: productCategory.data});
        updateStateStock(productCategory.data);
      }
      setTimeout(()=>setLoadingContent(false), 1000)

    }
  };

  const cartBackup = async () => {
    cleanCart();
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const requestResponse = await request.get(`ampmProduct/fromCart/${props.storeId}/${orderId}`);
    if(requestResponse.status === HttpStatus.OK){
      updateStateStock(requestResponse.data);
      requestResponse.data.map((e:any) => {
          dispatch({
            type: 'ADD-CARD',
            payload: {
              [e.models[0].id]: props.products[e.models[0].id]
            }
          });
      });
    }
  };

  const getPaymentMethods = async () => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const payMethodRequest = await request.get('paymethod');

    let payments:any[] = [];
    if (payMethodRequest.status === HttpStatus.OK) {
      payments = payMethodRequest.data.sort((a:any, b:any) => a.sort - b.sort);
    } else if (payMethodRequest.status === HttpStatus.NOT_FOUND) {
      Utils.showNotificationMessage(
        'info',
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__INFO_NOTIFICATION_TITLE_EMPTY_PAYMENT_METHODS'}),
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__INFO_NOTIFICATION_MESSAGE_EMPTY_PAYMENT_METHODS'})
      );
    } else {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__ERROR_NOTIFICATION_TITLE_FETCHING_PAYMENT_METHODS'}),
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__ERROR_NOTIFICATION_MESSAGE_FETCHING_PAYMENT_METHODS'})
      );
    }

    setPaymentMethods(payments);
  };

  const getProductCategories = async () => {
    const request = new HttpRequest(`https://api.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const productCategoriesRequest = await request.get(`ampmProduct/getValidCategories/19`);

    let categoriesData:any = [];
    if (productCategoriesRequest.status === HttpStatus.OK) {
      const treeCategories = getTreeCategory(productCategoriesRequest.data);

      setAllCategories(productCategoriesRequest.data)
      setCategories(treeCategories);
      setNonFilteredCategories(treeCategories);
    } else if (productCategoriesRequest.status === HttpStatus.NOT_FOUND) {
      Utils.showNotificationMessage(
        'info',
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__INFO_NOTIFICATION_TITLE_EMPTY_CATEGORIES'}),
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__INFO_NOTIFICATION_MESSAGE_EMPTY_CATEGORIES'})
      );
    } else {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__ERROR_NOTIFICATION_TITLE_FETCHING_CATEGORIES'}),
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__ERROR_NOTIFICATION_MESSAGE_FETCHING_CATEGORIES'})
      );
    }

    dispatch({
      type: 'ADD-CATEGORIES',
      payload: categoriesData
    });

  };

  const setShowLoyaltyParams = (params: boolean) => {
    dispatch({
      type: 'CURRENT-POINTS',
      payload: params
    });
  };

  const getLoyaltyPoints = async () => {
    let loyaltyPoints = 0;
    setLoyaltyClientId(state?.merchantParams?.identification);
    setLoyaltyPointsPath('ampmProduct/getLoyaltyClient');
    const request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
    const loyaltyPointsRequest = await request.get(`${loyaltyPointsPath}/${loyaltyClientId}/1`);

    if (loyaltyPointsRequest.status === HttpStatus.OK) {
      const loyaltyPointsData = loyaltyPointsRequest.data;
      if (Object.keys(loyaltyPointsData).length && loyaltyPointsData.colones_acumulados) {
        loyaltyPoints = Number(loyaltyPointsData.colones_acumulados.replaceAll(',', ''));
      }
    }

    setMerchantParams({
      ...state.merchantParams,
      loyaltyPoints: 10000
    });
  };

  const getTreeCategory = (categoriesData:any) => {
    let fathers:any[] = categoriesData.filter((val: { type: number; }) => {
      if (val.type === 0) {
        return val;
      }
    });

    fathers = fathers.sort((a, b) => a.sort - b.sort);

    return fathers.map((value: { name:string; }) => {
      let children:any[] = categoriesData.filter((val: { parent:string; }) => {
        if (val.parent === value.name) {
          return val;
        }
      });

      children = children.sort((a, b) => a.sort - b.sort);

      return Object.assign(value, {children: children});
    });
  };

  const updateStateStock = (products: any) => {
    let stateTemp = state.products[merchant] ? state.products[merchant] : [];

    let productsToAdd = products.filter((product: any) => {
      return !stateTemp.some((e: any) => e.name === product.name);
    });

    let total = [...stateTemp, ...productsToAdd];

    dispatch({
      payload: total,
      type: 'ADD-PRODUCTS'
    });
  };

  if (loading) {
    return <BarsStep merchant={merchant}/>;
  }

  return (
    <div style={{maxWidth: '100vw', overflow: 'hidden'}}>
      {

        <>
          <div className={'Editing-header-container'}>
            <div className={'Editing-header-subContainer'}>{logo}</div>
            <div className={'Editing-header-subContainer'}>
              {showSearch && !promotionScreen ?
                <div className={'New-header-search-container'}>
                  <NewSearchBar
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                    setSearchContent={setSearchContent}
                    merchant={merchant}
                    searchValue={searchValue}
                    setProducts={setProducts}
                    setSearchValue={setSearchValue}
                    setLoadingContent={setLoadingContent}
                  />
                </div> :
                <></>}
              {!showSearch  && !promotionScreen  ? <SearchOutlined onClick={() => setShowSearch(!showSearch)}
              className={'Editing-header-Search'}
              style={{color: '#b9b9b9'}}/> :<SearchOutlined className={'Editing-header-Search'}
                                                            style={{color:'transparent'}}/>}
              <div className={`Cashier-header-cart`}>
                <span style={{color: ``}}
                  className={cart.total ? 'Cashier-header-cart__full' : 'Cashier-header-cart__empty'}>
                  {
                    state.merchantParams?.suggestedOrder && loadingCart ?
                      <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
                        <FormattedMessage id={'HEADER_CASHIER__LABEL_LOADING'}/>
                      </Spin> :
                      (Object.keys(cart).filter(e=>e!=='total'&&e!=="nhlv2g").length)
                  }
                </span>
                <NewCart
                  priceWithPoints={priceWithPoints}
                  setDiscountPercent={props.setDiscountPercent}
                  discountPercent={props.discountPercent}
                  pickupValues={props.pickupValues}
                  addressValues={props.addressValues}
                  SubmitPickup={props.SubmitPickup}
                  SubmitAddress={props.SubmitAddress}
                  selectedPickup={props.selectedPickup}
                  editing={true}
                  orderId={orderId}
                  setLoading={setLoading}
                  loadingCart={loadingCart}
                  getOrder={props.getOrder}
                  setValidStock={setValidStock}
                  setCurrent={props.setCurrent}
                  setLoadingCart={setLoadingCart}
                  paymentMethods={paymentMethods}
                  setPriceWithPoints={setPriceWithPoints}
                />
              </div>
            </div>
          </div>
        </>

      }

      {props.ShowAddress}

      {
        promotionScreen ?
          <PromotionScreen loadingPromo={loadingPromo} storeId={props.storeId} setPromotionScreen={setPromotionScreen} editing visible={promotionVisible} changePercent={getProductsByPercent} updateStateStock={updateStateStock} viewChange={changeView}
             promos={promos} merchant={merchant}/>
          :
          <>
            {( !loadCategories && content) ?
              <div className={'editingOrderCashier'}>
                <AMPMFilter
                  filters={filters}
                  order={order}
                  products={products}
                  state={state}
                  editing={true}
                  promos={promos}
                  lastCategory={lastCategory}
                  category={category}
                  merchant={merchant}
                  setOrder={setOrder}
                  changeView={changeView}
                  handleView={handleView}
                  setCategory={setCategory}
                  breadcrumbs={breadcrumbs}
                  idStores={props.storeId}
                  setProducts={setProducts}
                  visible={promotionVisible}
                  setShowPromo={setShowPromo}
                  setFiltering={setFiltering}
                  setCategories={setCategories}
                  setVirtualPath={setVirtualPath}
                  setBreadcrumbs={setBreadcrumbs}
                  categoryFilter={categoryFilter}
                  setVisible={setPromotionVisible}
                  setLastCategory={setLastCategory}
                  getTreeCategory={getTreeCategory}
                  setLoadProducts={setLoadProducts}
                  setFilterContent={setFilterContent}
                  setSearchContent={setSearchContent}
                  updateStateStock={updateStateStock}
                  setLoadCategories={setLoadCategories}
                  setLoadingContent={setLoadingContent}
                  setPromotionScreen={setPromotionScreen}
                  productsWithoutCategory={productsWithoutCategory}
                />;
              </div> :
              <div style={{marginTop: '1rem', display: 'none'}}></div>
            }

            {(!loadingContent && !loadCategories && content) ? content : <div style={{marginTop:"2rem"}}><BarsStep merchant={merchant}/></div>}
          </>
      }
    </div>
  );
}
